import {Grid} from "@mui/material"
import React, { useEffect,useState} from "react"
import AlertMessage from "src/components/alert-message"
import DatalakeService from "src/services/datalake-service"
import PropTypes from 'prop-types'
import ParameterTable from "src/components/table/parameter-table"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"
import { TokenService } from "src/services/token-service"


const Emissoes = ({empresa,loading }) => {
    const datalakeservice = new DatalakeService()
    const tokenService = new TokenService()
    const [userAdmin, setUserAdmin] = useState(false)
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })
    const [data, setData] = useState([])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })

    useEffect(() => {
        if (empresa) {
            const userIsAdmin = tokenService.verifyUserIsAdmin()
            setUserAdmin(userIsAdmin)
            loadData()
        } 
    }, [empresa])


    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const loadData = () => {
        const empresaPrimeiraParte = empresa && typeof empresa === "object" ? empresa.CPFCNPJ : ""
    
    
        loading(true)
        datalakeservice.loademissoes(empresaPrimeiraParte)
            .then((response) => {
                loading(false)
    
                const { body } = response
    
                if (body && body.data) {
                    const { data, count, next, previous } = body
                    const list = data.map((obj, index) => ({
                        ...obj,
                        PUEmissao: typeof obj.PUEmissao === "number" ? obj.PUEmissao.toLocaleString("pt-BR", { minimumFractionDigits: 2 }) : obj.PUEmissao,
                        TaxaAccrual: typeof obj.TaxaAccrual === "number" ? obj.TaxaAccrual.toLocaleString("pt-BR", { minimumFractionDigits: 2 }) : obj.TaxaAccrual,
                        id: index,
                        Emissao: obj.Emissao ? dayjs(obj.Emissao).format(DATE_FORMAT_SHOW) : "",
                        Vencimento: obj.Vencimento ? dayjs(obj.Vencimento).format(DATE_FORMAT_SHOW) : "",
                        
                    }))
    
                    setPagination({ count: count, next: next, previous: previous })
                    setData(list)
                } else {
                    openAlert("Dados não encontrados na resposta.", "warning")
                    setPagination({ count: 0, next: null, previous: null })
                    setData([])
                }
            })
            .catch((error) => {
                loading(false)
                console.error("Erro ao buscar dados:", error)
                setPagination({ count: 0, next: null, previous: null })
                setData([])
            })
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            flex: true,
            minWidth: 100, 
            maxWidth: 100, 
        },
        {
            field: 'CETIP',
            headerName: 'Cetip',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable: false
        },
        {
            field: 'TipoAtivo',
            headerName: 'Tipo Ativo',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable: false
        },
        {
            field: 'Emissao',
            headerName: 'Data Emissão',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            typeComponent: 'date',
            editable: false
        },
        {
            field: 'Vencimento',
            headerName: 'Data de Vencimento',
            flex: 1,
            minWidth: 130,
            maxWidth: 300,
            typeComponent: 'date',
            editable:  false,
            renderHeader: () => (
                <span style={{ whiteSpace: "pre-line", textAlign: "center", display: "block", lineHeight: "1.2",color: '#000000DE',fontWeight: 425 ,fontsize: 1,fontfamily: "Roboto" }}>
                  Data de {"\n"}Vencimento
                </span>
              )
        },
        {
            field: 'DataResgateAntecipado',
            headerName: 'Data de resgate Antecipado',
            flex: 1,
            minWidth: 130,
            maxWidth: 300,
            typeComponent: 'date',
            editable:  false,
            renderHeader: () => (
                <span style={{ whiteSpace: "pre-line", textAlign: "center", display: "block", lineHeight: "1.2",fontWeight: 425 ,  }}>
                  Data de Resgate{"\n"}Antecipado
                </span>
              )
            
        },
        {
            field: 'Indicador',
            headerName: 'Indicador',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable:  false,
        },
        {
            field: 'TaxaAccrual',
            headerName: 'Taxa Accrual',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable:  false,
        },
        {
            field: 'Rating_C2P',
            headerName: 'Score C2P',
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            editable:  false,
        }, ...(userAdmin ? [{
            field: 'DataValidade_C2P',
            headerName: 'Validade C2P',
            flex: 1,
            minWidth: 120,
            maxWidth: 120,
            editable: false,
        }] : []),
        {
            field: 'Rating_Moodys',
            headerName: 'Moody\'s',
            flex: 1,
            minWidth: 120,
            maxWidth: 120,
            editable:  false,
        },
        {
            field: 'Rating_Fitch',
            headerName: 'Fitch',
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            editable:  false,
        },
        {
            field: 'Rating_SP',
            headerName: 'S&P',
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            editable:  false,
        },
    ]
    
    return (
        <Grid>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid item xs={12} md={12} sm={12} xl={12}>
                {empresa?.RazaoSocial && (
                    <ParameterTable
                        columns={columns}
                        data={data}
                        pagination={pagination}
                        disableDeleteColumn={true}
                    />
                )}
            </Grid>
        </Grid>

    )
}

Emissoes.propTypes = {
    loading: PropTypes.func,
    empresa: PropTypes.object.isRequired,
}

export default Emissoes
import React, {useState} from 'react'
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material"
import DatePickerSelect from "../../../../components/date/date-picker-select"
import MarketToMarketChart from "./market-to-market-chart"

const MarketToMarket = () => {
    const [dateSelectedDate, setSelectedDate] = useState("")

    const onChangeDatePickerDateTime = () => {
        setSelectedDate("")
    }

    return <>

    {/* items de selecao */}
    <Grid container direction="row" spacing={2} alignItems={"flex-end"}>
        <Grid item xs={12} md={4} xl={3}>
            <FormControl fullWidth>
                <InputLabel id="curva-spread-label">Curva de Spread</InputLabel>
                <Select
                    labelId="curva-spread-label"
                    value={""}
                    label="Curva de Spread"
                    // onChange={(e) => setSelect1(e.target.value)}
                >
                    <MenuItem value="a">A</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={"auto"} xl={"auto"}>
            <DatePickerSelect
                label="Data Referência"
                onChange={onChangeDatePickerDateTime}
                value={dateSelectedDate}
            />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
            <FormControl fullWidth>
                <InputLabel id="janela-negocio-label">Janela Negócios (DU)</InputLabel>
                <Select
                    labelId="janela-negocio-label"
                    value={""}
                    label="Janela Negócios (DU)"
                    // onChange={(e) => setSelect1(e.target.value)}
                >
                    <MenuItem value="a">A</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    </Grid>

    {/* gráfico */}
    <Grid container direction={"row"} spacing={2} alignItems={"center"}>
        <Grid item xs={12} xl={12} md={12}>
            <MarketToMarketChart />
        </Grid>
    </Grid>
</>
}

export default MarketToMarket
import { Button, Grid, TextField,Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import PropTypes from 'prop-types'
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"
import SkeletonLine from "src/components/skeleton-line"
// import { formatDecimalCaseReplaceComma, formatDecimalCaseWithComma } from "src/utils/format-decimal"
// import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"
import DatalakeService from "src/services/datalake-service"
import ArticleIcon from '@mui/icons-material/Article'
import { IconButton } from '@mui/material'



const tableDefault = [
    {
        cells: ["Razão Social: ", "CNPJ: ", "Código CVM: "]
    }
]

const UltimoFatoRelevante = ({admin, dadosEmpresa }) => {
    const ultimoNegocioDefault = { data_referencia: null, data_entrega: null, categoria: null, tipo:null, link:null }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const [ultimoNegocioState, setUltimoNegocioState] = useState(ultimoNegocioDefault)
    const [loadingUltimoNegocio, setLoadingUltimoNegocio] = useState(false)
    const [rowsUltimoNegocio, setRowsUltimoNegocio] = useState([])
    const [table, setTable] = useState(tableDefault)
    const calcService = new DatalakeService()
    const codcvm = dadosEmpresa.codigoCVM ? Number(dadosEmpresa.codigoCVM) : null
    
    useEffect(() => {
        if (codcvm === null) {
            // Quando codcvm for null, resetar os dados e limpar os estados
            setUltimoNegocioState(ultimoNegocioDefault)
            setRowsUltimoNegocio([])
            setTable(tableDefault)
        } else {
            loadData(codcvm)
            update()
        }
    }, [dadosEmpresa])

    const ordenarPorDataReferencia = (dados) => {
        return dados.sort((a, b) => 
            dayjs(b.data_referencia, DATE_FORMAT_SHOW).unix() - 
            dayjs(a.data_referencia, DATE_FORMAT_SHOW).unix()
        )
    }


    const loadData = () => {
        setLoadingUltimoNegocio(true)

        calcService.loadFatoRelevante(codcvm).then((response) => {
                const { body } = response
                const {results} = body

                if (!results || results.length === 0) { 
                    setUltimoNegocioState(ultimoNegocioDefault) // Define um estado padrão
                    return []
                }
                buildTable()

                const processedResults = results.map((obj) => ({
                    id: obj.ID,
                    data_referencia: dayjs(obj.DataReferencia).format(DATE_FORMAT_SHOW),
                    data_entrega: dayjs(obj.DataEntrega).format(DATE_FORMAT_SHOW),
                    empresa: obj.Empresa,
                    categoria: obj.Categoria,
                    tipo: obj.Tipo,
                    assunto: obj.Assunto,
                    status: obj.Status,
                    modalidade: obj.Modalidade,
                    link: obj.Link,
                    criadoem: obj.CriadoEm,
                    atualizadoem: obj.AtualizadoEm
                }))

                const orderedRows = ordenarPorDataReferencia([...processedResults]).map((obj, index) => ({ ...obj, id: index }))

                setRowsUltimoNegocio(orderedRows)
                setUltimoNegocioState(orderedRows[0]) // Primeiro registro
                console.log("Estado Atualizado:", orderedRows[0])
                return orderedRows
            })
            .catch(() => [])
            .finally(() => {
                setLoadingUltimoNegocio(false)
            })
    }
    
    const update = () => {
        loadData()
        buildTable()
    }

    const updateFatoRelevante = () => {
        loadData()
        setOpenMostrarTodos(true)
        buildTable()
    }

    const buildTable = () => {
            setTable(
                        [
                            {
                                cells: [`Razão Social: ${dadosEmpresa.razaoSocial}`, `CNPJ: ${dadosEmpresa.cnpj}`]
                            },
                            {
                                cells: [`Setor: ${dadosEmpresa.setor}`, `Subsetor: ${dadosEmpresa.subsetor}`]
                            }
                        ]
                    )
    }
    
    

    return <>
        {loadingUltimoNegocio ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                {ultimoNegocioState.data_referencia || ultimoNegocioState.categoria ? (
                <>
                    <Grid item xs={12} md={1.5} sm={2} xl={1.5}>
                    <TextField fullWidth label="Data Referência" value={ultimoNegocioState.data_referencia} />
                    </Grid>
                    <Grid item xs={24} md={6.5} sm={6} xl={6.5}>
                    <TextField fullWidth label="Fato Relevante" value={ultimoNegocioState.assunto} />
                    </Grid>
                    <Grid item xs={6} md={1} sm={1} xl={1}>
                        <IconButton component="a" href={ultimoNegocioState.link} color="primary" target="_blank" rel="noopener noreferrer">
                            <ArticleIcon sx={{ fontSize: 48 }}/>
                        </IconButton>
                    </Grid>
                                    </>
                ) : (
                <Grid item xs={8}>
                    <Typography variant="body2" color="black" align="center">
                     Esse devedor não possui fatos relevantes na CVM.
                    </Typography>
                </Grid>
                )}
                <Grid item xs={6} md={3} sm={3} xl={3}>
                    <Button fullWidth variant="contained" onClick={updateFatoRelevante}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Histórico Fato Relevante"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>

                        <ParameterTable
                            columns={[
                                { field: 'data_referencia', typeComponent: 'date', headerName: 'Data Referencia', minWidth: 100, editable: admin },
                                { field: 'data_entrega', typeComponent: 'date', headerName: 'Data Entrega', minWidth: 100, editable: admin },
                                { field: 'categoria', headerName: 'Categoria', minWidth: 200, editable: admin },
                                { field: 'tipo', headerName: 'Tipo', minWidth: 600, editable: admin },
                                { field: 'link', headerName: 'Link', minWidth: 100, editable: admin, renderCell: (params) => <IconButton component="a" href={params.value} color="primary" target="_blank" rel="noopener noreferrer"><ArticleIcon /></IconButton> },
                                ...(admin ? [
                                    { field: 'criadoem', headerName: 'Criado Em', minWidth: 300, editable: true },
                                    { field: 'atualizadoem', headerName: 'Atualizado Em', minWidth: 300, editable: true }
                                ] : [])

                            ]}
                            data={rowsUltimoNegocio}
                            setData={setRowsUltimoNegocio}
                            createRow={() => {}}
                            deleteRow={() => {}}
                            updateRow={() => {}}
                            loadData={loadData}
                            disableDeleteColumn={true}
                            disableAddRow={true}
                            buttonAddVariant={""}
                            buttonAddTitle={""}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoFatoRelevante.propTypes = {
    value: PropTypes.string,
    codcvm: PropTypes.string,
    dadosEmpresa: PropTypes.array,
    loading: PropTypes.func,
    admin: PropTypes.bool,

}

export default UltimoFatoRelevante
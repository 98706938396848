import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DATALAKE_CVM_CADASTRO_EMPRESAS_PATH } from 'src/utils/api-routes'
import ParameterDatalakeCVMCadastroEmpresasTable from 'src/pages/parameters/parameters-datalake/tables/parameter-datalake-cvm-cadastro-empresas-table'

const TableCadastroCvm = ({ loading, empresa }) => {

    const [filters, setFilters] = useState([])
    const [tableKey, setTableKey] = useState(Math.random())
    const PAGE_DEFAULT_SIZE = 25
    const PAGE_DEFAULT_INIT = 0
    const [pagination] = useState({ init: PAGE_DEFAULT_INIT, end: PAGE_DEFAULT_SIZE })
    
    useEffect(() => {
        loadFilter()
    }, [empresa])

    const loadFilter = () => {
        let newFilters = []

        if (empresa && empresa !== undefined) {
            newFilters.push({ field: 'CPFCNPJ', value: empresa })
        }

        console.log(newFilters)
        setFilters(newFilters)
        setTableKey(Math.random())
    }

    return <ParameterDatalakeCVMCadastroEmpresasTable
        key={tableKey}
        customFilter={false}
        filters={filters}
        endpoint={DATALAKE_CVM_CADASTRO_EMPRESAS_PATH}
        loading={loading}
        pagination={pagination}
        />
}

TableCadastroCvm.propTypes = {
    loading: PropTypes.func,
    empresa: PropTypes.object,
}

export default TableCadastroCvm
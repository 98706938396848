import { LOTES_PROCESSADOS_PATH, ROUTINE_PRECOS_ANDADOS_PATH, ROUTINE_REBUILD_INDEX_CURVAS_PATH, ROUTINE_SPREADSEMISSAO_PATH, PRICES_CALCULATION_CETIP_PATH, LOTES_DADOS_EXTERNOS_PATH, ROUTINE_LIQUIDEZ_WORKFLOW_PATH, ROUTINE_CLIENTE_PRECOS_LOG_WORKFLOW_PATH, ROUTINE_PRECOS_EVENTOS_FINANCEIROS_WORKFLOW_PATH, ROUTINE_PRECOS_PROCESSADOS_WORKFLOW_PATH, ROUTINE_SPREADS_BRUTOS_TRATADOS_WORKFLOW_PATH, ROUTINE_SPREADSEMISSAO_WORKFLOW_PATH, ROUTINE_SPREADS_NEGOCIADOS_WORKFLOW_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"

export class RoutineService {

  constructor() {
    this.request = new ServiceRequest()
  }

  getAPPList() {
    return [
      { value: 'json', description: "json" },
      { value: 'csv', description: "csv" },
      //{ value: 'xlsx', description: "xlsx" },
    ]
  }
  async sendFile(dateSelected, Type, selectedFile) {
    
      if (!dateSelected || !Type || !selectedFile) {
          throw new Error("Tipo da extensao, data de calculo e arquivo são obrigatórios.")
      }

    const formData = new FormData()

    formData.append('data_referencia', dateSelected)
    formData.append('tipo_extensao', Type)
    formData.append('arquivo', selectedFile)

    return this.request.postFormData(ROUTINE_PRECOS_ANDADOS_PATH, formData)
  }

  async startRoutine(path, body) {
    return this.request.post(path, body)
  }

  async startRebuild() {
    return this.request.post(ROUTINE_REBUILD_INDEX_CURVAS_PATH)
  }
  

  async startRoutineAndados() {
    return this.request.post( {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  }

  async startarPrecosProcessadosWorkflow(data_inicial, data_final){
    return this.request.post(ROUTINE_PRECOS_PROCESSADOS_WORKFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final} )
  }

  async startarSpreadsBrutosTratadosWorkflow(data_referencia){
    return this.request.post(ROUTINE_SPREADS_BRUTOS_TRATADOS_WORKFLOW_PATH, {"data_referencia": data_referencia} )
  }

  async startarSpreadsEmissao(data_inicial, data_referencia){
    return this.request.post(ROUTINE_SPREADSEMISSAO_PATH, {"data_inicial": data_inicial, "data_referencia": data_referencia} )
  }

  async startarSpreadEmissaoWorkflow(data_inicial, data_final){
    return this.request.post(ROUTINE_SPREADSEMISSAO_WORKFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final} )
  }

  async startarSpreadNegociadoWorkflow(data_inicial, data_final){
    return this.request.post(ROUTINE_SPREADS_NEGOCIADOS_WORKFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final} )
  }

  async startarLiquidezWorkflow(data_inicial, data_final){
    return this.request.post(ROUTINE_LIQUIDEZ_WORKFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final} )
  }

  async startarClientePrecosLogWorkflow(data_referencia){
    return this.request.post(ROUTINE_CLIENTE_PRECOS_LOG_WORKFLOW_PATH, {"data_referencia": data_referencia} )
  }

  async startarPrecosEventosFinanceirosWorkflow(data_referencia){
    return this.request.post(ROUTINE_PRECOS_EVENTOS_FINANCEIROS_WORKFLOW_PATH, {"data_referencia": data_referencia} )
  }

  async startarCalculationCetip(data_referencia, cetip, tipo_calculo) {
    try {
        const response = await this.request.post(
            PRICES_CALCULATION_CETIP_PATH, 
            {
                "data_referencia": data_referencia, 
                "cetip": cetip, 
                "tipo_calculo": tipo_calculo
            }
        )
        return response
    } catch (error) {
        console.error("Erro ao fazer o cálculo CETIP", error)
        throw error 
    }
}

  

  async getRoutinesInfo() {
    return this.request.get(`${LOTES_PROCESSADOS_PATH}?limit=200`)
  }

  verifyTableStatus(value) {
    let text = "Não informado"
    let color = "error"
    switch (value) {
      case "DONE":
        text = "SUCESSO"
        color = "success"
        break
      case "IN_PROGRESS":
        text = "EM PROGRESSO"
        color = "primary"
        break
      case "PENDING":
        text = "PENDENTE"
        color = "warning"
        break
      case "ERROR":
        text = "ERRO"
        color = "error"
        break
      case "FILE_ERROR":
        text = "ERRO NO ARQUIVO"
        color = "error"
        break
    }

    return {
      text: text,
      color: color
    }
  }


  async getRoutinesWorkflowInfo() {
    return this.request.get(`${LOTES_DADOS_EXTERNOS_PATH}?limit=200`)
  }

  verifyTableStatusWorkflow(value) {
    let text = "Não informado"
    let color = "error"
    switch (value) {
      case "SETUP_TASK":
        text = "SETUP_TASK"
        color = "primary"
        break
      case "GENERATING_MESSAGE":
        text = "GENERATING_MESSAGE"
        color = "primary"
        break   
      case "IN_PROGRESS":
        text = "EM PROGRESSO"
        color = "primary"
        break
      case "FILE_PROCESSING":
        text = "ARQUIVO EM PROGRESSO"
        color = "primary"
        break
      case "EXCEEDED_RETRIES":
        text = "ARQUIVO EM PROGRESSO"
        color = "primary"
        break
      case "PROCESS_DONE":
        text = "ARQUIVO EM PROGRESSO"
        color = "success"
        break
      case "ERROR":
        text = "ERRO"
        color = "error"
        break
      case "READY_TO_CALCULATE":
        text = "READY_TO_CALCULATE"
        color = "primary"
        break  
      case "CALCULATING":
        text = "CALCULANDO"
        color = "primary"
        break
      case "CALCULATE_DONE":
        text = "CALCULADO"
        color = "success"
        break
      case "REPORT_IN_PROGRESS":
        text = "REPORT_IN_PROGRESS"
        color = "primary"
        break
      case "REPORT_DONE":
        text = "REPORT_DONE"
        color = "success"
        break
      case "NOTIFICATION_IN_PROGRESS":
        text = "NOTIFICATION_IN_PROGRESS"
        color = "primary"
        break
      case "NOTIFICATION_DONE":
        text = "NOTIFICATION_DONE"
        color = "success"
        break
    }

    return {
      text: text,
      color: color
    }
  }
}

import {
    BACEN_TAXA_MEDIA_PF_PATH,
    BACEN_TAXA_MEDIA_PJ_PATH,
    RAZAO_RATING_PATH,
    BV_MODELO_SCORE_PATH,
    PD_RATING_PATH,
    TIPO_RECEBIVEL_PATH,
    TIPO_GARANTIA_PATH,
    SCORE_RATING_PATH,
    SCORE_BUREAU_RATING_PATH,
    BACEN_SCR_PATH,
    BACEN_TAX_FEE_PATH,
    Bacen_PDD_PATH,
    Bacen_Inadimplencia_PATH,
    PD_Spread_EAD_Recebivel_PATH,
    Score_Bureau_Recebivel_PATH,
    PD_Empresas_PATH
} from "src/utils/api-routes"


export const parameterTableList = [
    { value: "Bacen_Inadimplencia", description: "Bacen_Inadimplencia" },
    { value: "Bacen_PDD", description: "Bacen_PDD" },
    { value: "Bacen_SRC", description: "Bacen_SRC" },
    { value: "Bacen_Taxa_Media_PF", description: "Bacen_Taxa_Media_PF" },
    { value: "Bacen_Taxa_Media_PJ", description: "Bacen_Taxa_Media_PJ" },
    { value: "Bacen_Taxas_Juros", description: "Bacen_Taxas_Juros" },
    { value: "BV_modelo_score", description: "BV_modelo_score" },
    { value: "PD_Empresas", description: "PD_Empresas" },
    { value: "PD_Rating", description: "PD_Rating" },
    { value: "PD_Spread_EAD_Recebivel", description: "PD_Spread_EAD_Recebivel" },
    { value: "Razao_Rating", description: "Razao_Rating" },
    { value: "Score_Bureau_Rating", description: "Score_Bureau_Rating" },
    { value: "Score_Rating", description: "Score_Rating" },
    { value: "Tipo_Garantia", description: "Tipo_Garantia" },
    { value: "Tipo_Recebivel", description: "Tipo_Recebivel" },
    { value: "Score_Bureau_Recebivel", description: "Score_Bureau_Recebivel"}
]

export const parameterEndpoint = [
    { value: "Bacen_Taxa_Media_PF", endpoint: BACEN_TAXA_MEDIA_PF_PATH },
    { value: "Bacen_Taxa_Media_PJ", endpoint: BACEN_TAXA_MEDIA_PJ_PATH },
    { value: "Razao_Rating", endpoint: RAZAO_RATING_PATH },
    { value: "BV_modelo_score", endpoint: BV_MODELO_SCORE_PATH },
    { value: "PD_Empresas", endpoint: PD_Empresas_PATH },    
    { value: "PD_Rating", endpoint: PD_RATING_PATH },
    { value: "PD_Spread_EAD_Recebivel", endpoint: PD_Spread_EAD_Recebivel_PATH },
    { value: "Tipo_Recebivel", endpoint: TIPO_RECEBIVEL_PATH },
    { value: "Tipo_Garantia", endpoint: TIPO_GARANTIA_PATH },
    { value: "Score_Rating", endpoint: SCORE_RATING_PATH },
    { value: "Score_Bureau_Rating", endpoint: SCORE_BUREAU_RATING_PATH },
    { value: "Bacen_SRC", endpoint: BACEN_SCR_PATH },
    { value: "Bacen_Taxas_Juros", endpoint: BACEN_TAX_FEE_PATH },
    { value: "Bacen_PDD", endpoint: Bacen_PDD_PATH },
    { value: "Bacen_Inadimplencia", endpoint: Bacen_Inadimplencia_PATH },
    { value: "Score_Bureau_Recebivel", endpoint: Score_Bureau_Recebivel_PATH},
]

import {
    RATER_MM_COTACOES_PATH,
    RATER_MM_COTACOES_ACOES_B3_AJUSTADO_PATH,
    RATER_MM_PD_PATH,
    RATER_MM_RESULTADO_EMPRESA_PATH,
    RATER_MM_VOLATILIDADE_TICKER_PATH,
    RATER_MM_TICKER_CNPJ_PATH,
    RATER_USER_CADASTRO_EMPRESA_PATH,
    RATER_USER_SETORIAL_PATH,
    RATER_MM_RESULTADO_RATING_PATH,
} from "src/utils/api-routes"

export const parameterRaterTableList = [
    { value: "RT_UserCadastroEmpresas", description: "RT_UserCadastroEmpresas" },
    { value: "RT_UserSetorial", description: "RT_Usersetorial" },
    { value: "RT_MMCotacoes", description: "RT_MMCotacoes" },
    { value: "RT_MMCotacoesAcoesB3Ajustado", description: "RT_MMCotacoesAcoesB3Ajustado" },
    { value: "RT_MMPD", description: "RT_MMPD" },
    { value: "RT_MMResultadoEmpresa", description: "RT_MMResultadoEmpresa" },
    { value: "RT_MMResultadoRating", description: "RT_MMResultadoRating" },
    { value: "RT_MM_Ticker_CNPJ", description: "RT_MM_Ticker_CNPJ" },
    { value: "RT_MMVolatilidadeTicker", description: "RT_MMVolatilidadeTicker" },

]

export const parameterRaterEndpoint = [
    { value: "RT_MMCotacoes", endpoint: RATER_MM_COTACOES_PATH },
    { value: "RT_MMCotacoesAcoesB3Ajustado", endpoint: RATER_MM_COTACOES_ACOES_B3_AJUSTADO_PATH },
    { value: "RT_MMPD", endpoint: RATER_MM_PD_PATH },
    { value: "RT_MMResultadoEmpresa", endpoint: RATER_MM_RESULTADO_EMPRESA_PATH },
    { value: "RT_MMResultadoRating", endpoint: RATER_MM_RESULTADO_RATING_PATH },
    { value: "RT_MM_Ticker_CNPJ", endpoint: RATER_MM_TICKER_CNPJ_PATH },
    { value: "RT_MMVolatilidadeTicker", endpoint: RATER_MM_VOLATILIDADE_TICKER_PATH },
    { value: "RT_UserCadastroEmpresas", endpoint: RATER_USER_CADASTRO_EMPRESA_PATH },
    { value: "RT_UserSetorial", endpoint: RATER_USER_SETORIAL_PATH },

]
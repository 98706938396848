import React, { useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useState } from 'react'
import PropTypes from 'prop-types'

const DatePickerSelect = (props) => {
    const [date, setDate] = useState(dayjs())

    useEffect(() => {
        if (props.value && dayjs(props.value).isValid()) {
            setDate(dayjs(props.value))
        } else {
            setDate(dayjs())
        }
    }, [props.value])

    const changeDate = (value) => {
        setDate(value)
        props.onChange(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label={props.label || "Data"}
                    value={date}
                    format="DD/MM/YYYY"
                    onChange={changeDate}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

DatePickerSelect.propTypes = {
    onChange: PropTypes.func.isRequired, 
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}

export default DatePickerSelect
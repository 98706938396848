import { Box, InputLabel, MenuItem, Select } from "@mui/material"
import React from "react"
import PropTypes from 'prop-types'

const SelectBooleanValue = ({ item, applyValue, labelSelect, labelTrue, labelFalse }) => {

    return (<Box
        sx={{
            display: 'flex',
            alignItems: 'bottom',
            height: '100%',
        }}
    >
        <InputLabel id="demo-simple-select-label">{labelSelect}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            focused
            fullWidth
            label={labelSelect}
            value={item.value || ""}
            onChange={(event) =>
                applyValue({ ...item, value: event.target.value })
            }
        >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value={labelTrue}>{labelTrue}</MenuItem>
            <MenuItem value={labelFalse}>{labelFalse}</MenuItem>
        </Select>
    </Box>
    )
}

SelectBooleanValue.propTypes = {
    item: PropTypes.object,
    applyValue: PropTypes.func,
    labelSelect: PropTypes.string,
    labelTrue: PropTypes.string,
    labelFalse: PropTypes.string,
}


const CustomFilterBoolean = ({
    labelTrue = "Sim",
    labelFalse = "Não",
    labelFilter = "é igual a",
    labelSelect = "Valor"
}) => [
        {
            label: labelFilter,
            value: 'equals',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null
                }
                return ({ value }) => {
                    const booleanValue = value ? labelTrue : labelFalse
                    return booleanValue === filterItem.value
                }
            },
            InputComponent: (props) => <SelectBooleanValue
                {...props}
                labelSelect={labelSelect}
                labelTrue={labelTrue}
                labelFalse={labelFalse}
            />
        },
    ]

export default CustomFilterBoolean
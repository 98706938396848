export const API_PATH = process.env.REACT_APP_API_URL

// auth
export const RESET_PASSWORD_PATH = "/cadastro/api/v1/usuarios/reset_password"
export const TOKEN_PATH = "/cadastro/api/v1/auth"
export const RESET_PASSWORD_CONFIRM_PATH = "/cadastro/api/v1/usuarios/reset_password_confirm"

// clients
export const CLIENTS_PATH = "/cadastro/api/v1/clientes"
export const CLIENTS_PRICE_PATH = "/cadastro/api/v1/clientes/precos"
export const CLIENTE_CURVAS_LIST_PATH = "/cadastro/api/v1/cliente-curvas-list"//register-curve lista get
export const CLIENTE_CURVAS_UPDATE_PATH = "/cadastro/api/v1/cliente-curvas-update"//register-curve post update


// cetips
export const CETIPS_PATH = "/datalake/api/v1/cetips"

// users
export const USERS_PATH = "/cadastro/api/v1/usuarios"
export const USERS_ME_PATH = "/cadastro/api/v1/usuarios/me"

// curves
export const CURVES_PATH = "/datalake/api/v1/tabela-curvas"

// calc
export const CALC_CURVES_TYPE_TAX_PATH = "/calculo/api/v1/curvas/tipo-taxas"
export const CALC_CURVES_TYPE_PATH = "/calculo/api/v1/curvas/tipo-curvas"
export const CALC_IR_PATH = "/calculo/api/v1/aliquota-ir"
export const CALC_FAIXA_DURATION_PATH = "/calculo/api/v1/faixa-duration"
export const CALC_GROUP_ACTIVE_PATH = "/calculo/api/v1/grupo-ativo"
export const CALC_GROUP_INDICATOR_PATH = "/calculo/api/v1/grupo-indicador"
export const CALC_SETORIAL_PATH = "/calculo/api/v1/setorial"
export const CALC_ID_CURVAS_PATH = "/calculo/api/v1/curvas"
export const CALC_MULTIPLICADOR_LIQUIDEZ_PATH = "/calculo/api/v1/multiplicador-liquidez"
export const CALC_REGRAS_OUTLIERS_PATH = "/calculo/api/v1/regras-outliers"
export const CALC_SCORE_RATING_PATH = "/calculo/api/v1/score-rating"
export const CALC_REGRAS_VOLATILIDADE_PATH = "/calculo/api/v1/regras-volatilidade"
export const CALC_ALIAS_INDICADORES_PATH = "/calculo/api/v1/alias-indicadores"
export const CALC_CRITERIOS_LIQUIDEZ_PATH = "/calculo/api/v1/criterios-liquidez"
export const CALC_CURVAS_NSS_INICIAL_PATH = "/calculo/api/v1/curvas-nss-incial"
export const CALC_CURVAS_FILTERED_PATH = "/calculo/api/v1/curvas/filtered-curvas"


// datalake
export const DATALAKE_ALIAS_NOMES_DEVEDORES_PATH = "/datalake/api/v1/alias-nomes-devedores"
export const DATALAKE_CONTRAPARTE_PATH = "/datalake/api/v1/contraparte"
export const DATALAKE_RATING_DEVEDOR_PATH = "/datalake/api/v1/rating-devedor"
export const DATALAKE_RATING_EMISSAO_PATH = "/datalake/api/v1/rating-emissao"
export const DATALAKE_PRECOS_PROCESSADOS_PATH = "/datalake/api/v1/precos-processados"
export const DATALAKE_PRECOS_SPREADS_INDICATIVOS_PATH = "/datalake/api/v1/precos-spreads-indicativos"
export const DATALAKE_PRECOS_TUNEL_PATH = "/datalake/api/v1/precos-tunel"
export const DATALAKE_PRECOS_SREADS_RELATIVOS_PATH = "/datalake/api/v1/precos-spreads-relativos"
export const DATALAKE_CVM_FATOS_RELEVANTES_PATH = "/datalake/api/v1/cvm-fatos-relevantes"
export const DATALAKE_LIQUIDEZ_PATH = "/datalake/api/v1/liquidez"
export const DATALAKE_INDICE_INFLACAO_PATH="/datalake/api/v1/indice-inflacao"
export const DATALAKE_B3_ATIVOS_REG_PATH = "/datalake/api/v1/b3-ativos-reg"
export const DATALAKE_B3_NEG_ANALITICO_PATH = "/datalake/api/v1/b3-neg-analitico"
export const DATALAKE_B3_NEG_CONSOLIDADO_PATH = "/datalake/api/v1/b3-neg-consolidado"
export const DATALAKE_FINCS_MARKET_DATA_PATH = "/datalake/api/v1/fincs-marketdata"
export const DATALAKE_FINCS_JUROS_PATH = "/datalake/api/v1/fincs-juros"
export const DATALAKE_FINCS_EVENTOS_PATH = "/datalake/api/v1/fincs-eventos"
export const DATALAKE_SPREADS_NEGOCIADOS = "/datalake/api/v1/spreads-negociados"
export const DATALAKE_TAXAS_JUROS = "/datalake/api/v1/taxas-juros"
export const DATALAKE_RISK_FREE = "/datalake/api/v1/coleta-de-taxas-e-risk-free"
export const DATALAKE_SPREADS_EMISSAO = "/datalake/api/v1/spreads-emissao"
export const DATALAKE_SPREADS_IF_NEG = "/datalake/api/v1/spreads-if-neg"
export const DATALAKE_CURVAS_SPREADS_INDICATIVOS = "/datalake/api/v1/curvas-spreads-indicativos"
export const DATALAKE_CALENDARIO_INFLACAO = "/datalake/api/v1/calendario-inflacao"
export const DATALAKE_PRECOS_ANDADOS_PATH = "/datalake/api/v1/precos-andados"
export const DATALAKE_EMISSAO_CARACTERISTICAS_PATH = "/datalake/api/v1/caracteristicas-emissao"
export const DATALAKE_EMISSAO_LTV_PATH = "/datalake/api/v1/ltv-emissao"
export const DATALAKE_SPREADS_INDICATIVOS_BRUTO_PATH = "/datalake/api/v1/spreads-indicativos-bruto"
export const DATALAKE_ATUALIZACAO_INDICES_PATH = "/datalake/api/v1/atualizacao-indices"
export const DATALAKE_PREVIA_INFLACAO_PATH = "/datalake/api/v1/previa-inflacao"
export const DATALAKE_SPREADS_DEVEDOR_PATH = "/datalake/api/v1/spreads-devedor"
export const DATALAKE_SPREADS_LIQUIDEZ_PATH = "/datalake/api/v1/spreads-liquidez"
export const DATALAKE_SPREADS_SEC_PULVERIZADO_PATH = "/datalake/api/v1/spreads-sec-pulverizado"
export const DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH = "/datalake/api/v1/eventos-credito-devedor"
export const DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH = "/datalake/api/v1/eventos-credito-emissao"
export const DATALAKE_PRECOS_EVENTOS_FINANCEIROS_PATH = "/datalake/api/v1/precos-eventos-financeiros"
export const DATALAKE_PRECOS_INADIMPLENTES_LGD_PATH = "/datalake/api/v1/precos-inadimplentes-lgd"
export const DATALAKE_BACEN_SEGMENTACAO_PATH = "/datalake/api/v1/bacen-segmentacao"
export const DATALAKE_BACEN_CADASTRO_CONGLOMERADO_PATH = "/datalake/api/v1/bacen-cadastro-conglomerado"
export const DATALAKE_BACEN_CADASTRO_EMPRESA_PATH = "/datalake/api/v1/bacen-cadastro-empresa"
export const DATALAKE_CVM_CADASTRO_EMPRESAS_PATH="/datalake/api/v1/cvm-cadastro-empresas"
export const DATALAKE_CVM_CADASTRO_EMISSOR_PATH="/datalake/api/v1/cvm-cadastro-emissor"
export const DATALAKE_CVM_CADASTRO_SITUACAO_PATH="/datalake/api/v1/cvm-cadastro-situacao"
export const DATALAKE_EMPRESAS_PATH="/datalake/api/v1/empresas-dl"
export const DATALAKE_TAXAS_TR_PATH="/datalake/api/v1/taxas-tr"
export const DATALAKE_GRUPO_ECONOMICO_PATH="/datalake/api/v1/grupo-economico"
export const DATALAKE_SETORIAL_PERSPECTIVA_PATH="/datalake/api/v1/setorial-perspectiva"
export const DATALAKE_EMPRESAS_LIST_PATH ="/datalake/api/v1/empresas-list"
export const DATALAKE_SETOR_LIST_PATH ="/datalake/api/v1/datalake/api/v1/setor-list"
export const DATALAKE_ULTIMO_PD_PATH ="/datalake/api/v1/ultimo-pd"
export const DATALAKE_INFO_ADICIONAIS_PATH ="/datalake/api/v1/info-adicionais"
export const DATALAKE_EMPRESAS_FILE_PATH ="/datalake/api/v1/empresas-file"
export const DATALAKE_PERIODICIDADE_BALANCO_PATH ="/datalake/api/v1/periodicidade-balanco"
export const DATALAKE_BROADCAST_COTACOES_ACOES_B3_PATH ="/datalake/api/v1/broadcast-cotacoes-acoes-b3"
export const DATALAKE_B3_TICKER_CNPJ_PATH ="/datalake/api/v1/b3-ticker-cnpj"


// prices
export const PRICE_PATH = "/datalake/api/v1/tabela-precos"
export const PRICE_TABLE_LAST_DATE_PATH = "/datalake/api/v1/tabela-precos/ultima-data"
export const PRICE_PU_PATH = "/calculo/api/v1/precos-processados/pu"
export const PRICE_LAST_DATE_PATH = "/datalake/api/v1/precos-spreads-indicativos/ultimas-datas"
export const PRICE_UPDATE_SPREADS_PATH = "/datalake/api/v1/precos-spreads-indicativos/atualizacao"
export const PRICES_CLIENT_PATH = "/cadastro/api/v1/precos-cliente"
export const PRICES_CALCULATION_CETIP_PATH = "/calculo/api/v1/calculo-precos-por-cetip" 
export const CUSTOMERS_PRICE_LOG_PATH = "/cadastro/api/v1/precos-cliente-log-data"

// IMPORT
export const IMPORT_DATALAKE_PATH = "/datalake/api/v1/importacao-workflow"
export const IMPORT_FULL_DATALAKE_PATH = "/datalake/api/v1/importacao-workflow"

//delete
export const DELETE_DATALAKE_PATH = "/datalake/api/v1/delecao-workflow"

// receivables
export const RECEIVABLE_CONCESSIONS_PATH = "/recebiveis/api/v1/concessao"
export const RECEIVABLE_WALLETS_PATH = "/recebiveis/api/v1/carteira"
export const BACEN_TAXA_MEDIA_PF_PATH = "/recebiveis/api/v1/bacen-taxa-media-pf"
export const BACEN_TAXA_MEDIA_PJ_PATH = "/recebiveis/api/v1/bacen-taxa-media-pj"
export const RAZAO_RATING_PATH = "/recebiveis/api/v1/razao-rating"
export const BV_MODELO_SCORE_PATH = "/recebiveis/api/v1/bv-modelo-score"
export const PD_RATING_PATH = "/recebiveis/api/v1/pd-rating"
export const TIPO_RECEBIVEL_PATH = "/recebiveis/api/v1/tipo-recebivel"
export const TIPO_GARANTIA_PATH = "/recebiveis/api/v1/tipo-garantia"
export const SCORE_RATING_PATH = "/recebiveis/api/v1/score-rating"
export const SCORE_BUREAU_RATING_PATH = "/recebiveis/api/v1/score-bureau-rating"
export const BACEN_SCR_PATH = "/recebiveis/api/v1/bacen-scr"
export const BACEN_TAX_FEE_PATH = "/recebiveis/api/v1/bacen-taxas-juros"
export const Bacen_PDD_PATH = "/recebiveis/api/v1/bacen-pdd-adicional"
export const Bacen_Inadimplencia_PATH = "/recebiveis/api/v1/bacen-inadimplencia"
export const PD_Spread_EAD_Recebivel_PATH ="/recebiveis/api/v1/pd-spread-ead-recebivel"
export const Score_Bureau_Recebivel_PATH = "/recebiveis/api/v1/score-bureau-recebivel"
export const PD_Empresas_PATH = "/recebiveis/api/v1/pd-empresas"
export const EXAMPLE_CONCESSION_FILE = "/recebiveis/api/v1/exemplo/concessao"
export const EXAMPLE_WALLETS_FILE = "/recebiveis/api/v1/exemplo/estoque"

//ROUTINES
export const ROUTINE_LIQUIDEZ_PATH = "/datalake/api/v1/liquidez-rotina"
export const ROUTINE_VOLATILIDADE_PATH = "/calculo/api/v1/curvas-volatilidade-rotina"
export const ROUTINE_CURVA_PATH = "/datalake/api/v1/curvas-taxas-nss-rotina"
export const ROUTINE_SPREAD_RELATIVO_PATH = "/datalake/api/v1/precos-spreads-relativos-rotina"
export const ROUTINE_SPREAD_NEGOCIADO_PATH = "/calculo/api/v1/spread-negociado-rotina"
export const ROUTINE_PRECOS_PATH = "/calculo/api/v1/precos-processados-rotina"
export const LOTES_PROCESSADOS_PATH = "/calculo/api/v1/lotes-processados"
export const ROUTINE_PRECOS_TUNEL_PATH = "/calculo/api/v1/precos-tunel-rotina"
export const ROUTINE_PRECOS_ANDADOS_PATH = "/calculo/api/v1/precos-andados-rotina"
export const ROUTINE_REBUILD_INDEX_CURVAS_PATH = "/calculo/api/v1/rebuild-index-curvas-taxas-nss"
export const ROUTINE_SPREADSEMISSAO_PATH = "/calculo/api/v1/spreads-emissao-v2-rotina"
export const ROUTINE_SPREADSBRUTO_PATH = "/calculo/api/v1/spreads-brutos-rotina"
export const ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH = "/calculo/api/v1/eventos-financeiros-rotina"
export const ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH = "/calculo/api/v1/eventos-financeiros-pumtm-rotina"
export const ROUTINE_RISKFREE_WORKFLOW_PATH = "/calculo/api/v1/tratamento-riskfree/workflow"
export const ROUTINE_LIQUIDEZ_WORKFLOW_PATH =  "/calculo/api/v1/liquidez/workflow"
export const ROUTINE_SPREAD_RELATIVO_WORKFLOW_PATH = "/calculo/api/v1/spread-relativo/workflow"
export const ROUTINE_PRECOS_PROCESSADOS_WORKFLOW_PATH = "/calculo/api/v1/precos-processados/workflow"
export const ROUTINE_VOLATILIDADE_WORKFLOW_PATH = "/calculo/api/v1/curvas-volatilidade/workflow"
export const ROUTINE_CLIENTE_PRECOS_LOG_WORKFLOW_PATH = "/cadastro/api/v1/cliente-precos-log/workflow"
export const ROUTINE_SPREADSEMISSAO_WORKFLOW_PATH = "/calculo/api/v1/spreads-emissao/workflow"
export const ROUTINE_SPREADS_NEGOCIADOS_WORKFLOW_PATH = "/calculo/api/v1/spreads-negociados/workflow"
export const ROUTINE_PRECOS_EVENTOS_FINANCEIROS_WORKFLOW_PATH = "/calculo/api/v1/precos-eventos-financeiros/workflow"
export const ROUTINE_SPREADS_BRUTOS_TRATADOS_WORKFLOW_PATH = "/calculo/api/v1/spreads-brutos-tratados/workflow"


//INTEGRATION
export const INTEGRATION_B3AtivosReg_PATH = "/datalake/api/v1/b3ativos-reg-rotina"
export const INTEGRATION_B3NegAnalitico_PATH = "/datalake/api/v1/b3neg-analitico-rotina"
export const INTEGRATION_B3NegConsolidado_PATH = "/datalake/api/v1/b3neg-consolidado-rotina"
export const INTEGRATION_BACEN_CADASTRO_PATH = "/datalake/api/v1/bacen-cadastro-rotina"
export const INTEGRATION_BACEN_CADASTRO_WORKFLOW_PATH = "/datalake/api/v1/bacen-cadastro/workflow"
export const INTEGRATION_BACEN_TAXAS_JUROS_PATH = "/datalake/api/v1/bacen-taxas-juros/rotina"
export const INTEGRATION_CVM_BALANCOS_WORKFLOW_PATH = "/datalake/api/v1/cvm-balancos-workflow"
export const INTEGRATION_CVM_CADASTRO_WORKFLOW_PATH = "/datalake/api/v1/cvm-cadastro-workflow"
export const INTEGRATION_CVMFATOSRELEVANTES_PATH = "/datalake/api/v1/cvm-fatos-relevantes-rotina"
export const INTEGRATION_CVMFATOSRELEVANTES_WORKFLOW_PATH = "/datalake/api/v1/cvm-fatos-relevantes/workflow"
export const INTEGRATION_COLETADETAXASERISKFREE_WORKFLOW_PATH = "/datalake/api/v1/coleta-de-taxas-e-risk-free-workflow"
export const INTEGRATION_EstoqueRegistrado_PATH = "/datalake/api/v1/estoque-registrado-rotina"
export const INTEGRATION_MARKETDATA_AIRFLOW_PATH = "/datalake/api/v1/fincs-rotina/workflow"
export const INTEGRATION_FULL_MARKETDATA_AIRFLOW_PATH = "/datalake/api/v1/fincs-full-rotina/workflow"
export const INTEGRATION_FINCS_SERIE_HISTORICA_AIRFLOW_PATH = "/datalake/api/v1/fincs-serie-historica/workflow"
export const INTEGRATION_FINCS_MARKETDATA_CETIPS_AIRFLOW_PATH = "/datalake/api/v1/fincs-marketdata-cetips/workflow"
export const INTEGRATION_MARKETDATA_PATH = "/datalake/api/v1/fincs-rotina"
export const INTEGRATION_PREVIA_INFLACAO_ANBIMA_PATH = "/datalake/api/v1/previa-inflacao-anbima"
export const INTEGRATION_PREVIAINFLACAO_PATH = "/datalake/api/v1/fincs/previa-inflacao"
export const INTEGRATION_PREVIA_INFLACAO_WORKFLOW_PATH = "/datalake/api/v1/previa-inflacao-workflow"
export const INTEGRATION_RISKFREE_PATH = "/datalake/api/v1/coleta-de-taxas-e-risk-free-rotina"
export const INTEGRATION_TAXAS_RECEBIVEIS_MERCADO_PATH = "/recebiveis/api/v1/taxas-recebiveis-mercado-rotina"
export const LOTES_DADOS_EXTERNOS_PATH = "/datalake/api/v1/workflows"
export const REPORT_DADOS_WORKFLOW_PATH = "/datalake/api/v1/workflows/reports"
//export const INTEGRATION_SPREADSEMISSAO_PATH = "/calculo/api/v1/spreads-emissao-v2-rotina"

// ACCESS
export const GROUPS_PATH = "/cadastro/api/v1/grupos"
export const PERMISSIONS_PATH = "/cadastro/api/v1/permissoes"
export const REQUEST_TRIAL_PATH = "/cadastro/api/v1/permissoes/solicitar-trial"
export const REPORT_ERROR_PATH = "/cadastro/api/v1/permissoes/reportar-erro"

// CHARACTERISTICS
export const CALC_DATA_RECENTE_CHARACTERISTICS = "/calculo/api/v1/consulta-data-mais-recente-precos-processados"
export const CALC_ISSUE_CHARACTERISTICS = "/calculo/api/v1/consulta-caracteristicas"
export const POST_PARAMETERS_CALC = "/calculo/api/v1/consulta-parametros-calculo"
export const POST_GUARANTEE_CALC = "/calculo/api/v1/consulta-garantias"
export const POST_MEMORY_CALC_PUMTM = "/calculo/api/v1/memoria-calculo-pumtm"
export const POST_MEMORY_CALC_PUPAR = "/calculo/api/v1/memoria-calculo-pupar"
export const POST_MEMORY_RESULT_PRICE = "/calculo/api/v1/consulta-resultados-precos-processados"
    
// REPORT ERROR
export const CALC_REPORT_ERROR = "/calculo/api/v1/report-errors"

// SIMULADOR
export const SIMULADOR_HISTORICO = "/simulador/api/v2/historico"
export const SIMULADOR_SUBORDINACAO_SIMULADO = "/simulador/api/v2/simulacao/subordinacao"
export const SIMULADOR_SIMULACAO = "/simulador/api/v2/simulacao"
export const DADOS_CARTEIRA_ENTRADA = "/recebiveis/api/v1/estoque-lote"
export const SIMULADOR_SUBORDINACAO = "/simulador/api/v2/subordinacao"

//RATER
export const RATER_MM_COTACOES_PATH = "/rater/api/v1/mm-cotacoes"
export const RATER_MM_COTACOES_ACOES_B3_AJUSTADO_PATH = "/rater/api/v1/mm-cotacoes-acoes-b3-ajustado"
export const RATER_MM_PD_PATH ="/rater/api/v1/mm-pd"
export const RATER_MM_RESULTADO_EMPRESA_PATH ="/rater/api/v1/mm-resultado-empresa"
export const RATER_MM_VOLATILIDADE_TICKER_PATH ="/rater/api/v1/mm-volatilidade-ticker"
export const RATER_MM_TICKER_CNPJ_PATH ="/rater/api/v1/mm-ticker-cnpj"
export const RATER_USER_CADASTRO_EMPRESA_PATH ="/rater/api/v1/rt-usercadastroempresa"
export const RATER_USER_SETORIAL_PATH ="/rater/api/v1/rt-usersetorial"
export const RATER_MM_RESULTADO_RATING_PATH ="/rater/api/v1/mm-resultado-rating"
export const RATER_SETORIAL_PATH ="/rater/api/v1/rt-setorial"
export const RATER_SETORIAL_UPDATE_PATH ="/rater/api/v1/rt-setorial-update"
export const RATER_SETORIAL_FILE_PATH ="/rater/api/v1/rt-setorial-file"

// EMISSAO
export const CETIP_EMISSAO_PATH = "/datalake/api/v1/cetips/emissao"
export const ULTIMO_RATING_EMISSAO_PATH = "/datalake/api/v1/rating-emissao/ultimo"
export const ULTIMO_RATING_DEVEDOR_PATH = "/datalake/api/v1/rating-devedor/ultimo"
export const ULTIMO_EVENTO_CREDITO_EMISSAO_PATH = "/datalake/api/v1/eventos-credito-emissao/ultimo"
export const ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH = "/datalake/api/v1/eventos-credito-devedor/ultimo"
export const ULTIMO_SPREADS_NEGOCIADOS_PATH = "/datalake/api/v1/spreads-negociados/ultimo"
export const ULTIMO_INDICE_LIQUIDEZ_PATH = "/datalake/api/v1/liquidez/ultimo"
export const ULTIMO_PRECOS_PROCESSADOS_PATH = "/datalake/api/v1/precos-processados/ultimo"

// BOA VISTA
export const BOA_VISTA_HEALTH_PATH = "/recebiveis/api/v1/boa-vista/health"
import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { TokenService } from "src/services/token-service"
import InformacoesCadastrais from "./tab/caracteristicas-tab"
import AlertMessage from "src/components/alert-message"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Button     } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModalDialogPage from "src/components/modal-dialog"
import Loading from "src/components/loading"
import SkeletonTable from "src/components/skeleton-table"
import TableContraparte from "./tables/table_empresa_contraparte"
import TableCadastroBacen from "./tables/table_empresa_cadastro_bacen"
import TableCadastroCvm from "./tables/table_empresa_cadastro_cvm"
import UltimoPD from "./accordion/ultimo-pd"
import UltimoRatingDevedor from "./accordion/ultimo-rating-devedor"
import UltimoEventoCreditoDevedor from "./accordion/ultimo-evento-credito-devedor"
import UltimoFatoRelevante from "./accordion/ultimo-fato-relevante"
import DatalakeService from "src/services/datalake-service"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import FabButton from "src/components/fab-button"
import RegisterEmpresa from "./register/register-empresa"
// import IndicadorBalanco from "./accordion/indicador-balanco"



const EmpresaPage = ({ empresa }) => {
    const [userAdmin, setUserAdmin] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const tokenService = new TokenService()
    const calcService = new DatalakeService()
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [loadTab, setLoadTab] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openTableModal, setOpenTableModal] = useState({
        open: false,
        title: "",
        component: <></>,
    })
    const [ultimoEventoCreditoDevedorKey, setUltimoEventoCreditoDevedorKey] = useState(Math.random())
    const [ultimoRatingDevedorKey, setUltimoRatingDevedorKey] = useState(Math.random())
    const [openRegister, setOpenRegister] = useState(false)
    

    const cpfCnpj = empresa?.CPFCNPJ || ''
    const codigoCVM =empresa?.CodigoCVM || ''
    const raizcnpj =empresa?.RaizCNPJ || ''

    console.log("codigo cvm:",codigoCVM)

    const toggleAccordion = () => {
        setExpanded(!expanded)
    }

    const [dadosEmpresa, setDadosEmpresa] = useState({
        razaoSocial: "",
        nomeFantasia: "",
        cnpj: "",
        raizCNPJ: "",
        grupoEconomico: "",
        grupoEconomico_RaizCNPJ:"",
        mesFechamento: "",
        setor: "",
        subsetor: "",
        tickerB3: "",
        setorB3: "",
        codigoCVM: "",
        setorCVM: "",
        observacao: "",
        atividade: "",
        grupoAuditoria: "",
        grupoCustodiante: "",
        grupoCoordenadorLider: "",
        grupoFiduciario: "",
        grupoIFRecebedora: "",
        grupoSecuritizador: "",
        grupoRating: "",
        TipoCadastro: "",
        PeriodicidadeBalanco: ""
    })

    const loadData = () => {

        calcService.loadInfo(cpfCnpj).then((response) => {
            const { body } = response
            const { results } =  body
            
            const list = results

            console.log(list)
            preencherDadosEmpresa(empresa,list[0])
        })
    }
    
    const preencherDadosEmpresa = (empresa,list) => {
        console.log("empresa:",list)
        setDadosEmpresa({
            razaoSocial: empresa.RazaoSocial || "",
            nomeFantasia: empresa.NomeFantasia || "",
            cnpj: empresa.CPFCNPJ || "",
            raizCNPJ: empresa.RaizCNPJ || "",
            grupoEconomico: empresa.GrupoEconomico_Nome || "",
            grupoEconomico_RaizCNPJ: empresa.GrupoEconomico_RaizCNPJ || "",
            mesFechamento: list.MesFechamento || "",
            setor: empresa.Setor || "",
            subsetor: empresa.SubSetor || "",
            tickerB3: empresa.Ticker || "",
            setorB3: empresa.SetorB3 || "",
            codigoCVM: empresa.CodigoCVM || "",
            setorCVM: empresa.SetorCVM || "",
            observacao: list.Observacao || "",
            atividade: list.Atividade || "",
            grupoAuditoria: list.GrupoAuditoria || "",
            grupoCustodiante: list.GrupoCustodiante || "",
            grupoCoordenadorLider: list.GrupoCoordenadorLider || "",
            grupoFiduciario: list.GrupoFiduciario || "",
            grupoIFRecebedora: list.GrupoIFRecebedora || "",
            grupoSecuritizador: list.GrupoSecuritizador || "",
            siteRI: list.Site_RI || "",
            TipoCadastro:list.TipoCadastro ||"",
            PeriodicidadeBalanco: calcService.tratPeriodicidade(list.PeriodicidadeBalanco) ?? ""
        })
        setUltimoEventoCreditoDevedorKey(Math.random())
        setUltimoRatingDevedorKey(Math.random())
    }
    
    
    useEffect(() => {

        if (empresa) {
            setLoading(true)
            const userIsAdmin = tokenService.verifyUserIsAdmin()
            setUserAdmin(userIsAdmin)
            loadData()
            setLoading(false)
            
            if (!expanded) {
                toggleAccordion()
            }
        }
    
    }, [empresa])
  
    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }
    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenRegister(true)
        },
        color: "primary",
    }

    const afterRegister = (message, status) => {
        if (status === "error") {
            openAlert(message, status)
            return
        }

        openAlert(message, status)
        setOpenRegister(false)
    }

    return (<>
            <Loading show={loadTab} />
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid
                container
                direction="row"
                alignContent="flex-start"
                justifyContent="flex-start"
                spacing={0}
                sx={{
                    padding: 2,
                    height: "100%", // altura de 100% da Box
                }}>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                    <Accordion expanded={expanded} onChange={(e, isExpanded) => setExpanded(isExpanded)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Informações Cadastrais</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {loading ? <SkeletonTable /> : <> <InformacoesCadastrais data={dadosEmpresa} userAdmin={userAdmin} /> <br /></>}
                        {
                            userAdmin ?
                                <Grid container direction="row" alignItems={"center"} justifyContent="flex-start" spacing={2}>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (empresa.CPFCNPJ === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "Contraparte",
                                                component: <TableContraparte loading={setLoadTab} empresa={cpfCnpj} />
                                            })
                                        }}>Contraparte</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (empresa.CPFCNPJ === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "Cadastro CVM",
                                                component: <TableCadastroCvm loading={setLoadTab} empresa={cpfCnpj} />
                                            })
                                        }}>Cadastro CVM</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (empresa.CPFCNPJ === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "Cadastro BACEN",
                                                component: <TableCadastroBacen loading={setLoadTab} empresa={cpfCnpj} />
                                            })
                                        }}>Cadastro BACEN</Button>
                                    </Grid>
                                </Grid>
                                : <></>
                        }
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ultimo-rating-devedor-content"
                            id="ultimo-rating-devedor-content"
                        >
                            <Typography>Último Rating Devedor</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UltimoRatingDevedor admin={userAdmin} cpfCnpj={cpfCnpj} key={ultimoRatingDevedorKey} dadosEmpresa={dadosEmpresa} loading={setLoadTab} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ultimo-evento-credito-devedor-content"
                            id="ultimo-evento-credito-devedor-content"
                        >
                            <Typography>Último Evento de Crédito Devedor</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UltimoEventoCreditoDevedor admin={userAdmin} cpfCnpj={cpfCnpj} key={ultimoEventoCreditoDevedorKey} loading={setLoadTab} dadosEmpresa={dadosEmpresa} value={'AZUL12'} />
                        </AccordionDetails>
                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ultimo-fato-relevant-content"
                            id="ultimo-fato-relevante-content"
                        >
                            <Typography>Último Fato Relevante</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            { <UltimoFatoRelevante admin={userAdmin} codcvm={codigoCVM} dadosEmpresa={dadosEmpresa} loading={setLoadTab} /> }
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ultimo-pd-content"
                            id="ultimo-pd-content"
                        >
                            <Typography>Última Probabilidade de Default</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            { <UltimoPD admin={userAdmin} raiz_cnpj={raizcnpj}  dadosEmpresa={dadosEmpresa} /> }
                        </AccordionDetails>
                    </Accordion>

                    {/* <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="indicadores-balanco-content"
                            id="indicadores-balanco-content"
                        >
                            <Typography>Indicadores Financeiros e Balanço Patrimonial</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            { <IndicadorBalanco admin={userAdmin} raiz_cnpj={raizcnpj}  dadosEmpresa={dadosEmpresa} /> }
                        </AccordionDetails>
                    </Accordion> */}

                </Grid>
            </Grid>
            <ModalDialogPage open={openTableModal.open}
                title={openTableModal.title}
                close={() => {
                    setOpenTableModal({
                        open: false,
                        component: null,
                        title: null,
                    })
                }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            {openTableModal.component}
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>

            {
                <ModalDialogPage
                    open={openRegister}
                    title={"Cadastrar Características e Garantias da Emissão"}
                    close={() => {
                        setOpenRegister(false)
                    }}
                    button={<FabButton action={actionButton} />}>
                    <RegisterEmpresa afterRegister={afterRegister} />
                </ModalDialogPage>
            }
        </>
    )
} 

EmpresaPage.propTypes = {
    userAdmin: PropTypes.bool,
    loading: PropTypes.func,
    empresa: PropTypes.object.isRequired,
}

export default EmpresaPage

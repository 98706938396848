import React, {useEffect, useState, useRef} from "react"
import {BoxPlotController, BoxAndWiskers} from '@sgratzl/chartjs-chart-boxplot'
import {Line} from "react-chartjs-2"
import {v4 as uuidv4} from 'uuid'
import {Chart as ChartJS} from "chart.js/auto" // eslint-disable-line no-unused-vars
import zoomPlugin from 'chartjs-plugin-zoom'
import {Box, Button} from "@mui/material"

const numerosAleatorios = () => Array.from({length: 10}, () => String(Math.floor(Math.random() * 50)))
const gerarBoxplotCoerente = () => {
    const median = Math.floor(Math.random() * 30)

    const q1 = median - Math.floor(Math.random() * 3 + 5)
    const q3 = median + Math.floor(Math.random() * 3 + 5)

    const min = q1 - Math.floor(Math.random() * 3 + 5)
    const max = q3 + Math.floor(Math.random() * 3 + 5)

    return {min, q1, median, q3, max}
}

const bodyResponseExample = [
    {
        "label": "AZUL11",
        "type": "line",
        "data": numerosAleatorios()
    },
    {
        "label": "AERI11",
        "type": "line",
        "data": numerosAleatorios()
    },
    {
        "label": "AMZN11",
        "type": "scatter",
        "data": Array.from({length: 10}, (_, index) => ({
            x: index + 1,
            y: Math.floor(Math.random() * 30)
        }))
    },
    {
        type: 'boxplot',
        label: 'CMIG4',
        data: Array.from({length: 2}, gerarBoxplotCoerente),
    },
    {
        type: 'boxplot',
        label: 'EMBR3',
        data: Array.from({length: 4}, gerarBoxplotCoerente),
    },
    {
        type: 'boxplot',
        label: 'INBR32',
        data: Array.from({length: 9}, gerarBoxplotCoerente),
    },
]

const MarketToMarketChart = () => {
    const chartRef = useRef(null)
    const [controleCores, setControleCores] = useState([]) // nao deixar existir informacoes com a mesma cor
    const [xAxis, setXAxis] = useState({
        name: "",
        values: [],
    })
    const [dataset, setDataset] = useState([])

    let arrayItems = []
    useEffect(() => {
        ChartJS.register(zoomPlugin, BoxPlotController, BoxAndWiskers)
        loadChartData()
        // setDataset([
        //     {
        //         id: "123",
        //         label: `grupo 1`,
        //         data: ,
        //         fill: false,
        //         tension: 0.4,
        //         backgroundColor: "#000",
        //         borderColor: "#000",
        //         borderWidth: 2,
        //         pointRadius: 3
        //     },
        //     {
        //         id: "456",
        //         label: `grupo 2`,
        //         data: ["1", "2", "4", "6", "8", "8", "8", "8", "8", "8", "8", "8"],
        //         fill: false,
        //         tension: 0.4,
        //         backgroundColor: "#F40039",
        //         borderColor: "#F40039",
        //         borderWidth: 2,
        //         pointRadius: 3
        //     },
        //     {
        //         type: "scatter",
        //         label: "Ponto Extra",
        //         data: [
        //             {x: "1", y: 2.4},
        //             {x: "2", y: 3},
        //             {x: "3", y: 7},
        //             {x: "4", y: 5},
        //             {x: "5", y: 8},
        //             {x: "6", y: 9},
        //             {x: "7", y: 9},
        //             {x: "8", y: 9},
        //             {x: "9", y: 9},
        //         ],
        //         backgroundColor: "#D4E20F",
        //         borderColor: "#000",
        //         pointRadius: 6,
        //         pointStyle: "triangle",
        //     },
        //     {
        //         type: "scatter",
        //         label: "Ponto Extra 2",
        //         data: [
        //             {x: "1", y: 2},
        //             {x: "2", y: 4},
        //             {x: "3", y: 6},
        //             {x: "4", y: 8},
        //             {x: "5", y: 8},
        //             {x: "6", y: 8},
        //             {x: "7", y: 8},
        //             {x: "8", y: 8},
        //             {x: "9", y: 8},
        //             {x: "10", y: 8},
        //         ],
        //         backgroundColor: "#0C9DDB",
        //         borderColor: "#000",
        //         pointRadius: 6,
        //         pointStyle: "triangle",
        //     },
        //     {
        //         type: 'boxplot',
        //         label: 'Boxplot Grupo A',
        //         data: [
        //             {min: 1, q1: 2, median: 3, q3: 4, max: 5},
        //             {min: 2, q1: 3, median: 4, q3: 5, max: 6},
        //             {min: 0, q1: 2, median: 3, q3: 4, max: 6},
        //             {min: 1, q1: 2, median: 2.5, q3: 3.5, max: 4},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //             {min: 1.5, q1: 2.2, median: 3.1, q3: 3.8, max: 4.5},
        //         ],
        //         backgroundColor: 'rgba(75, 192, 192, 0.5)',
        //         borderColor: 'rgba(75, 192, 192, 1)',
        //         borderWidth: 1,
        //         barPercentage: 0.2,
        //     }
        // ])
        // setXAxis({
        //     name: "Gráfico Exemplo",
        //     values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
        // })
    }, [])

    const generateColor = () => {
        let newColor

        do {
            newColor = Math.floor(Math.random() * 0xffffff)
                .toString(16)
                .padStart(6, '0')
                .toLowerCase()
        } while (controleCores.includes(newColor))

        setControleCores(prev => [...prev, newColor])
        return `#${newColor}`
    }

    const geraLinha = (label, data) => {
        const color = generateColor()
        return {
            id: uuidv4(),
            label: label,
            data: data,
            fill: false,
            tension: 0.4,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            pointRadius: 3
        }
    }

    const geraScatter = (label, data) => {
        const color = generateColor()
        return {
            type: "scatter",
            label: label,
            data: data,
            backgroundColor: color,
            borderColor: color,
            pointRadius: 8,
            pointStyle: "triangle",
        }
    }

    const geraBoxPlot = (label, data) => {
        const color = generateColor()
        return {
            type: 'boxplot',
            label: label,
            data: data,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            barPercentage: 0.4,
        }
    }

    const generateXAxisValues = (data) => {
        let xAxisValues = []

        data.forEach((item) => {
            if (item.type === "scatter") {
                item.data.map((item) => xAxisValues.push(item.x))
            }
            // no box plor verificar a quantidade de items
            if (item.type === "line") {
                xAxisValues.push(item.data.map((item) => item))
            }
        })

        console.log(xAxisValues)
        return xAxisValues
    }

    const loadChartData = () => {
        bodyResponseExample.map((item) => {
            let itemGrafico

            switch (item.type) {
                case "line":
                    itemGrafico = geraLinha(item.label, item.data)
                    break
                case "scatter":
                    itemGrafico = geraScatter(item.label, item.data)
                    break
                case "boxplot":
                    itemGrafico = geraBoxPlot(item.label, item.data)
                    break
                default:
                    break
            }

            arrayItems.push(itemGrafico)
        })

        setDataset(arrayItems)
        setXAxis({
                name: "",
                values: generateXAxisValues(arrayItems),
            }
        )
    }

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "",
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = tooltipItem.dataset.label || ''
                        const value = tooltipItem.parsed.y || ''

                        return `${label}: ${value}`
                    }
                }
            },
            legend: {
                display: true,
                position: 'right', // top, bottom, left, right
                labels: {
                    boxWidth: 20,
                    boxHeight: 15,
                    padding: 25,
                    usePointStyle: true,
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                },
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: true
                    },
                    mode: 'xy',
                    overScaleMode: 'xy',
                    limits: {
                        x: {minRange: 1},
                        y: {minRange: 1}
                    }
                }
            }
        },
        transitions: {
            zoom: {
                animation: {
                    duration: 1000,
                    easing: 'easeOutCubic'
                }
            }
        },
        scales: {
            x: {
                display: true,
                max: undefined,
                title: {
                    display: true,
                    text: xAxis.name,
                    font: {
                        weight: 'bold',
                    },
                },
            },
            y: {
                type: "linear",
                beginAtZero: true,
                display: true,
                title: {
                    display: true,
                    text: "",
                    font: {
                        weight: 'bold',
                    },
                },
            },
        },
    }

    const resetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom()
        }
    }

    return (
        <>
            <Box sx={{height: '60vh', display: 'flex', flexDirection: 'column'}}>
                {/* Área do gráfico que ocupa o espaço restante */}
                <Box sx={{flexGrow: 1, position: 'relative'}}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={resetZoom}
                        sx={{
                            position: 'absolute',
                            top: 34,
                            right: 2,
                            zIndex: 10,
                            boxShadow: 1,
                            minWidth: 'auto',
                            padding: '4px 8px',
                            fontSize: '0.75rem'
                        }}
                    >
                        Redefinir zoom
                    </Button>

                    <Line
                        ref={chartRef}
                        data={{
                            labels: xAxis.values,
                            datasets: dataset
                        }}
                        options={{
                            ...options,
                            maintainAspectRatio: false // deixa o gráfico crescer com o container
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default MarketToMarketChart
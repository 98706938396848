import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import ArticleIcon from '@mui/icons-material/Article'

const InformacoesCadastrais = (props) => {

    const getValue = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }
        return props.data[value]
    }

    const columns = [
        { keyA: "Razão Social", valueA: getValue("razaoSocial"), keyB: "Nome Fantasia", valueB: getValue("nomeFantasia") },
        { keyA: "CNPJ", valueA: getValue("cnpj"), keyB: "Raiz CNPJ", valueB: getValue("raizCNPJ") },
        { keyA: "Grupo Econômico", valueA: getValue("grupoEconomico"), keyB: "País", valueB: getValue("País") },
        { keyA: "Tipo Cadastro", valueA: getValue("TipoCadastro"), keyB: "Situação Cadastral", valueB: getValue("SituacaoCadastral") },
        { keyA: "Mês Fechamento", valueA: getValue("mesFechamento"), keyB: "Periodicidade Balanço", valueB: getValue("PeriodicidadeBalanco") },      

        ...[
            getValue("siteRI")
                ? { keyA: "Site RI", valueA:( <a href={getValue("siteRI")} target="_blank" rel="noopener noreferrer"><ArticleIcon  color="primary" fontSize="large" /></a>), keyB: "Último Balanço", valueB: getValue("UltimoBalanço") }
                : null
        ].filter(Boolean), 

        { keyA: "Setor", valueA: getValue("setor"), keyB: "Subsetor", valueB: getValue("subsetor") },

        ...[
            getValue("tickerB3") || getValue("setorB3")
                ? { keyA: "Ticker B3", valueA: getValue("tickerB3"), keyB: "Setor B3", valueB: getValue("setorB3") }
                : null,
            getValue("codigoCVM") || getValue("setorCVM")
                ? { keyA: "Código CVM", valueA: getValue("codigoCVM"), keyB: "Setor CVM", valueB: getValue("setorCVM") }
                : null
        ].filter(Boolean), 
    
        { keyA: "Observação", valueA: getValue("observacao"), keyB: "Atividade", valueB: getValue("atividade") },
    ]


    // Campos que serão exibidos apenas para usuários com permissão Administrador
    if (props.userAdmin) {
        columns.push(
            { keyA: "Grupo Auditoria", valueA: getValue("grupoAuditoria"), keyB: "Grupo Custodiante", valueB: getValue("grupoCustodiante") },
            { keyA: "Grupo Coordenador Líder", valueA: getValue("grupoCoordenadorLider"), keyB: "Grupo Fiduciário", valueB: getValue("grupoFiduciario") },
            { keyA: "Grupo IF Recebedora", valueA: getValue("grupoIFRecebedora"), keyB: "Grupo Securitizador", valueB: getValue("grupoSecuritizador") },
        )
    }

    return (
        <>
            <TableColumns columns={columns} title={"Informações Cadastrais"} />
        </>
    )
}

InformacoesCadastrais.propTypes = {
    data: PropTypes.object,
    userAdmin: PropTypes.bool,
}

export default InformacoesCadastrais

import React from 'react'
import {Grid, Card, Typography, Box} from '@mui/material'
import PropTypes from 'prop-types'
import {CURRENT_PAGE_STORAGE_KEY} from "../../utils/constants"
import StorageService from "../../services/storage-service"
import {useNavigate} from "react-router-dom"

const ItemCard = ({item, index}) => {
    const storageService = new StorageService()
    const navigate = useNavigate()

    const goToPage = () => {
        storageService.saveItemLocalStorage(CURRENT_PAGE_STORAGE_KEY, item.id)
        navigate(item.route)
    }

    return (
        <Grid item key={index} xs={12} md="auto">
            <Card
                component="a"
                onClick={goToPage}
                sx={{
                    width: 170,
                    height: 190,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    padding: 2,
                    position: 'relative',
                    boxShadow: 3,
                    textDecoration: 'none',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6,
                    },
                }}
            >
                {/* Ícone no círculo */}
                <Box
                    sx={{
                        borderRadius: '50%',
                        width: 80,
                        height: 80,
                        border: '3px solid',
                        borderColor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 4,
                    }}
                >
                    {React.cloneElement(item.icon, {sx: {color: 'primary.main', fontSize: 40}})}
                </Box>

                {/* Texto no canto inferior */}
                <Typography
                    variant="body2"
                    align="center"
                    sx={{
                        padding: '8px',
                        position: 'absolute',
                        bottom: 0,
                        fontWeight: 500,
                        color: 'inherit',
                    }}
                >
                    {item.label}
                </Typography>
            </Card>
        </Grid>
    )
}

ItemCard.propTypes = {
    item: PropTypes.shape({
        icon: PropTypes.element.isRequired,
        label: PropTypes.string.isRequired,
        route: PropTypes.string,
        id: PropTypes.string
    }).isRequired,
    index: PropTypes.number.isRequired,
}

export default ItemCard
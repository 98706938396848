import {Grid, Typography} from '@mui/material'
import * as React from 'react'
import LayoutPage from 'src/components/page-layout/layout-page'
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded"
import CalculateIcon from "@mui/icons-material/Calculate"
import GradingIcon from "@mui/icons-material/Grading"
import PriceChangeIcon from "@mui/icons-material/PriceChange"
import StorageRoundedIcon from "@mui/icons-material/StorageRounded"
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded'
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"
import ItemCard from "./item-card"
import {
    CONCESSIONS_PAGE,
    CURVES_PAGE, DELETE_DATA_PAGE,
    EMISSAO_PAGE,
    EMPRESAS_PAGE,
    FIXED_INCOME_CALCULATOR_PAGE,
    IMPORT_PAGE,
    INTEGRATION_DATA_PAGE,
    PARAMETERS_DATALAKE_PAGE,
    PRICES_PAGE, PRODUCT_PAGE,
    RATER_CALCULADORA_PAGE,
    ROUTINES_HOME_PAGE,
    USER_PAGE,
    WALLETS_PAGE
} from "../../utils/routes"
import {TokenService} from "../../services/token-service"
import {useState} from "react"

const iconSize = 60

const itensSistema = [
    {
        icon: <RequestQuoteRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Precificação - Tabela PU/TIR",
        route: PRICES_PAGE,
        id: "menu_precos_tabela"
    },
    {
        icon: <CalculateIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Gestor de Recebíveis - Concessão",
        route: CONCESSIONS_PAGE,
        id: "menu_gr_operacoes"
    },
    {
        icon: <CalculateIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Gestor de Recebíveis - Carteira",
        route: WALLETS_PAGE,
        id: "menu_gr_carteiras"
    },
    {
        icon: <GradingIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Rater - Calculadora (em breve)",
        route: RATER_CALCULADORA_PAGE,
        id: "menu_rater_calculadora"
    },
    {
        icon: <PriceChangeIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Calculadora de Renda Fixa",
        route: FIXED_INCOME_CALCULATOR_PAGE,
        id: "menu_calculadora_calculadora_renda_fixa"
    },
]

const itensDatalake = [
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Emissão (beta)",
        route: EMISSAO_PAGE,
        id: "menu_data_lake_emissao"
    },
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Empresas (em breve)",
        route: EMPRESAS_PAGE,
        id: "menu_data_lake_empresas"
    },
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Curvas",
        route: CURVES_PAGE,
        id: "menu_curvas_tabela"
    },
]

const itensAdministrativo = [
    {
        icon: <AppRegistrationRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Acesso - Produtos",
        route: PRODUCT_PAGE,
        id: "menu_acesso_produtos"
    },
    {
        icon: <AppRegistrationRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Acesso - Usuários",
        route: USER_PAGE,
        id: "menu_acesso_usuarios"
    },
    {
        icon: <FunctionsRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "CreditCalc - Processo",
        route: "#",
        id: ""
    },
    {
        icon: <FunctionsRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "CreditCalc - Tratamento de Spreads",
        route: "#",
        id: ""
    },
    {
        icon: <FunctionsRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "CreditCalc - Contigência",
        route: ROUTINES_HOME_PAGE,
        id: "menu_calc_contigencia"
    },
]

const itensAdministrativosDatalake = [
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Integração de Dados Externos",
        route: INTEGRATION_DATA_PAGE,
        id: "menu_data_lake_market_data"
    },
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Consulta de Dados",
        route: PARAMETERS_DATALAKE_PAGE,
        id: "menu_data_lake_consulta_dados"
    },
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Importação",
        route: IMPORT_PAGE,
        id: "menu_data_lake_importacao_dados"
    },
    {
        icon: <StorageRoundedIcon color="primary" sx={{fontSize: iconSize}}/>,
        label: "Deleção",
        route: DELETE_DATA_PAGE,
        id: "menu_data_lake_exclusao_dados"
    },
]


const HomePage = () => {
    const [userAdmin, setUserAdmin] = useState(false)
    const tokenService = new TokenService()

    useState(() => {
        const userIsAdmin = tokenService.verifyUserIsAdmin()
        setUserAdmin(userIsAdmin)
    }, [])

    const iconeTexto = (listaMenus) => {
        return listaMenus.map((item, index) => (
           <Grid item key={index} xl={"auto"} md={"auto"} xs={"auto"}>
               <ItemCard key={index} item={item} index={index}/>
           </Grid>
        ))
    }

    return (
        <LayoutPage title="Home">
            <Grid container>
                <Grid item>
                    <Typography variant={"h4"}>Bem Vindo à C2P</Typography>
                </Grid>
            </Grid>

            <br/><br/>

            <Grid container spacing={8}>
                <Grid item xs={12} md={12} xl="auto">
                    <Grid container direction="column" spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Sistemas</Typography>
                        </Grid>
                        <br/>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    alignItems: "center",
                                }}
                                direction="row">
                                {iconeTexto(itensSistema)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl="auto">
                    <Grid container direction="column" spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Datalake</Typography>
                        </Grid>
                        <br/>
                        <Grid item xs={12} xl={4}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                {iconeTexto(itensDatalake)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {
                userAdmin && (
                    <>
                        <br/><br/>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={12} xl="auto">
                                <Grid container direction="column" spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Administração</Typography>
                                    </Grid>
                                    <br/>
                                    <Grid item xs={12} xl={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            direction="row"
                                            sx={{
                                                alignItems: "center",
                                            }}
                                        >
                                            {iconeTexto(itensAdministrativo)}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid item xs={12} xl={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            direction="row"
                                            sx={{
                                                alignItems: "center",
                                            }}
                                        >
                                            {iconeTexto(itensAdministrativosDatalake)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }

        </LayoutPage>
    )
}

export default HomePage
import {Box, Grid, Tab, Tabs, Button, Autocomplete, TextField} from "@mui/material"
import React, {useState, useEffect} from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"
import Balanços from "./demo_financeiras"
import EmpresaPage from "./empresa_page"
import OutroDocumentos from "./outros_documentos"
import GrupoEconomico from "./grupo_economico"
import Emissoes from "./emissoes"
import DatalakeService from "src/services/datalake-service"
//import { DATALAKE_EMPRESAS_LIST_PATH } from "src/utils/api-routes"
import ModalDialogPage from "src/components/modal-dialog"
import {DataGrid} from "@mui/x-data-grid"
import {TokenService} from "../../services/token-service"
import MarketToMarket from "./tab/market-to-market/market-to-market-tab"


const EmpresasPage = () => {
    const [data, setData] = useState([])
    const calcService = new DatalakeService()
    const tokenService = new TokenService()
    const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [modalCompleteList, setModalCompleteList] = useState(false)
    const [reportErrorMessage, setReportErrorMessage] = useState("")
    const [EmpresasList, setEmpresasList] = useState([])
    const [userAdmin, setUserAdmin] = useState(false)
    //const endpoint= DATALAKE_EMPRESAS_LIST_PATH

    const sendReportError = () => {
        console.log('enviando')
        setLoading(true)
        calcService.postReportError(reportErrorMessage, selectedOption)
            .then(() => {
                setOpenModalError(false)
                openAlert("Mensagem enviada com sucesso", "success")
            })
            .catch(() => {
                openAlert("Erro ao enviar mensagem, tente novamente", "error")
            }).finally(() => {
            setLoading(false)
        })
    }

    const loadData = () => {
        setIsLoading(true)

        calcService.loadList().then((response) => {
            setIsLoading(false)
            const {body} = response
            const {results} = body

            const list = results
                .filter((obj) => obj.Setor !== "Consórcio")
                .map((obj, index) => {
                    const formattedCPFCNPJ = obj.CPFCNPJ?.replace(/[^\w\s\u002D.,|]/g,)
                    const displayString = `${obj.RazaoSocial} | ${obj.CPFCNPJ}`

                    return {
                        ...obj,
                        id: index,
                        formattedCPFCNPJ,
                        displayString
                    }
                })
            setEmpresasList(list)
            setData(list)
            console.log(list)
        })
            .catch((error) => {
                setLoading(false)
                openAlert("Erro no carregamento de dados", "error")
                console.error("Erro ao buscar dados:", error)
                setData([])
            })
    }

    useEffect(() => {
        loadData()
    }, [])

    useState(() => {
        const userIsAdmin = tokenService.verifyUserIsAdmin()
        setUserAdmin(userIsAdmin)
    }, [])

    const removeDiacritics = (str) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, '')

    const filterAutocompleteOptions = (options, inputValue) => {
        // Permitir hífen e remover apenas caracteres indesejados
        const sanitizedInput = removeDiacritics(inputValue.toLowerCase().trim()).replace(/[^a-zA-Z0-9\s\u002D.,|]/g, '') // Hífen incluído explicitamente (\u002D)

        return options.filter((option) => {
            const formattedCNPJ = removeDiacritics(option.formattedCPFCNPJ.toLowerCase())
                .replace(/[^\d-]/g, '') // Mantém números e hífen

            const displayString = removeDiacritics(`${option.RazaoSocial.toLowerCase()} | ${option.formattedCPFCNPJ}`)

            return (
                displayString.includes(sanitizedInput) ||
                formattedCNPJ.includes(sanitizedInput) // Verifica o input com hífen preservado
            )
        })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({...alertMessageState, open: false})
    }

    const handleClickMostrarTodosTable = (data) => {
        const {row} = data

        setSelectedOption({
            ...row,
            displayString: `${row.RazaoSocial} - ${row.CPFCNPJ}`,
        })

        setModalCompleteList(false)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (<><EmpresaPage loading={setLoading} alert={openAlert} empresa={selectedOption}/></>)
            case 1:
                return (<><MarketToMarket loading={setLoading} alert={openAlert}/></>)
            case 2:
                return (<><Balanços loading={setLoading} alert={openAlert}/></>)
            case 3:
                return (<><Emissoes loading={setLoading} alert={openAlert} empresa={selectedOption}/></>)
            case 4:
                return (<><GrupoEconomico loading={setLoading} alert={openAlert} empresa={selectedOption}
                                          onSelectEmpresa={handleClickMostrarTodosTable}/></>)
            case 5:
                return (<><OutroDocumentos loading={setLoading} alert={openAlert}/></>)
            default:
                return null
        }
    }

    return (
        <LayoutPage title="Empresas" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message}
                          type={alertMessageState.type} close={closeAlert}/>
            <Grid direction={"row"} container alignItems="center" justifyContent="space-between" spacing={3}>
                <Grid item xs={6} md={8} sm={12}>
                    <Grid container direction={"row"} spacing={3} alignItems="center">
                        <Grid item xs={4} md={6} sm={6}>
                            <Autocomplete
                                options={data}
                                getOptionLabel={(option) => option.displayString || ''}
                                filterOptions={(options, {inputValue}) =>
                                    filterAutocompleteOptions(options, inputValue)
                                }
                                value={selectedOption}
                                onChange={(event, newValue) => setSelectedOption(newValue)}
                                loading={isLoading}
                                renderInput={(params) => (
                                    <TextField {...params} label="Razão Social ou CNPJ" variant="outlined"/>
                                )}
                                fullWidth
                                loadingText="Carregando opções..."
                            />
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button color={"primary"} variant={"contained"} onClick={() => setModalCompleteList(true)}>Consultar
                                Lista Completa

                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button color={"primary"} variant={"contained"} onClick={() => {
                        setReportErrorMessage(null), setOpenModalError(true)
                    }}>Reportar Erro
                    </Button>
                </Grid>
            </Grid>

            <br/>

            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{height: "100%"}}>
                    <Box
                        sx={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}
                              aria-label="tabs">
                            <Tab label={"EMPRESA"} key={0}/>
                            {userAdmin ? <Tab label={"MARKET TO MARKET"} key={1}/> : null}
                            {userAdmin ? <Tab label={"BALANÇOS"} key={2}/> : null}
                            <Tab label={"EMISSÕES"} key={3}/>
                            <Tab label={"GRUPO ECONÔMICO"} key={4}/>
                            {userAdmin ? <Tab label={"OUTROS DOCUMENTOS"} key={5}/> : null}
                        </Tabs>

                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Grid
                                container
                                direction="row"
                                alignContent="flex-start"
                                justifyContent="flex-start"
                                spacing={0}
                                sx={{
                                    padding: 2,
                                    height: "100%", // altura de 100% da Box
                                }}>
                                <Grid item xs={12} sm={12} md={12} xl={12} sx={{height: "100%"}}>
                                    {renderContent()}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <ModalDialogPage open={openModalError}
                             title="Reportar Erro"
                             close={() => {
                                 setOpenModalError(false)
                             }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                label="Mensagem"
                                variant="outlined"
                                placeholder="Descreva o erro ou inconsistência nas informações que encontrou que nosso time de suporte irá rever!"
                                multiline
                                focused
                                minRows={10}
                                value={reportErrorMessage}
                                onChange={(event) => setReportErrorMessage(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container direction="column" alignContent={"flex-end"} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button color={"primary"} variant={"contained"} onClick={sendReportError}>Reportar
                                Erro</Button>
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>

            <ModalDialogPage open={modalCompleteList}
                             title="Lista de Empresas"
                             close={() => {
                                 setModalCompleteList(false)
                             }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            <DataGrid
                                autoHeight
                                columns={[
                                    {field: 'CPFCNPJ', headerName: 'CPFCNPJ', width: 200},
                                    {field: 'CodigoCVM', headerName: 'Código CVM', width: 200},
                                    {field: 'Ticker', headerName: 'Ticker B3', width: 200},
                                    {field: 'RazaoSocial', headerName: 'Razão Social', width: 300},
                                    {field: 'NomeFantasia', headerName: 'Nome Fantasia', width: 300},
                                    {field: 'Setor', headerName: 'Setor', width: 300},
                                    {field: 'SubSetor', headerName: 'SubSetor', width: 300},
                                    {field: 'GrupoEconomico_Nome', headerName: 'Grupo Econômico', width: 300},

                                ]}
                                rows={EmpresasList}
                                onRowClick={handleClickMostrarTodosTable}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[25, 50, 100]}
                            />
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>
        </LayoutPage>
    )
}

export default EmpresasPage
import { Button, Grid, TextField,Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import DatalakeService from "src/services/datalake-service"
import PropTypes from 'prop-types'
import SkeletonLine from "src/components/skeleton-line"
import ParameterTable from "src/components/table/parameter-table"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"

const defaultTable = [
    {
        cells: ["Raiz CNPJ: ", "Data Referência: "]
    }
]

const UltimoPD = ({ admin, raiz_cnpj, dadosEmpresa }) => {
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const calcService = new DatalakeService()
    const [listaResultados, setListaResultados] = useState([])
    const [loadingRatingEmissao, setLoadingRatingEmissao] = useState(false)
    const [table, setTable] = useState(defaultTable)
    const [rowsUltimoNegocio, setRowsUltimoNegocio] = useState([])

    useEffect(() => {
        if (raiz_cnpj) { 
            console.log('UltimoRatingEmissao: ', raiz_cnpj)
            loadData(raiz_cnpj)
        }
    }, [dadosEmpresa])


    const buildTable = () => {
        setTable(
            [
                {
                    cells: [`Razão Social: ${dadosEmpresa.razaoSocial}`, `CNPJ: ${dadosEmpresa.cnpj}`]
                },
                {
                    cells: [`Setor: ${dadosEmpresa.setor}`, `Subsetor: ${dadosEmpresa.subsetor}`]
                }
            ]
        )
    }

    const loadData = () => {
        setLoadingRatingEmissao(true)
        calcService.loadPD(raiz_cnpj).then((response) => {
            const { body } = response
            const { results } = body

            const list = results.map((obj, index) => ({
                    ...obj,
                    id: index,
                    data_referencia: obj.DataReferencia ? dayjs(obj.DataReferencia).format(DATE_FORMAT_SHOW) : "",
                }))
            
            const orderedRows = ordenarPorDataReferencia([...list]).map((obj, index) => ({ ...obj, id: index }))
            setRowsUltimoNegocio(orderedRows)
            // Atualizando o estado com os dados filtrados
            setLoadingRatingEmissao(false)
            setListaResultados(list[0])
        }).catch(() => {
            setListaResultados([])
        }).finally(() => {
        })
    }
    const mostrar= () => {
        setOpenMostrarTodos(true)
        buildTable()
    }

    const ordenarPorDataReferencia = (dados) => {
        return dados.sort((a, b) => 
            dayjs(b.data_referencia, DATE_FORMAT_SHOW).unix() - 
            dayjs(a.data_referencia, DATE_FORMAT_SHOW).unix()
        )
    }

    // const minWidthAnaliseCredito = calculateMinWidth(rowsEmissao, "AnaliseCreditoEmissao")
    // const minWidthObservacao = calculateMinWidth(rowsEmissao, "Observacao")

    return <>
        {loadingRatingEmissao ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                {listaResultados && (listaResultados.data_referencia || listaResultados.PD) ? (
                    <>
                        <Grid item xs={12} sm={6} md={1.5} xl={1.5}>
                            <TextField fullWidth label="Data Referência" value={listaResultados.data_referencia} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7.5} xl={7.5}>
                            <TextField fullWidth label="PD" value={listaResultados.PD?.toString().replace('.', ',')} />
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={8}>
                        <Typography variant="body2" color="black" align="center">
                            Esse devedor não possui PD calculada.
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={3} md={3}  xl={3}>
                    <Button fullWidth variant="contained" onClick={mostrar}>Mostrar Todos</Button>
                </Grid>
            </Grid>

        }
        <ModalDialogPage open={openMostrarTodos}
            title="Histórico PD (Probabilidade de Default)"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                {/* <Loading show={loadingModal} /> */}
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <ParameterTable
                    columns={[
                        { field: 'data_referencia', typeComponent: 'date', headerName: 'Data Referencia', minWidth: 130, editable: admin },
                        { field: 'PD', typeComponent: 'date', headerName: 'PD', minWidth: 130, editable: admin, valueGetter: (params) => params.value ? params.value.toString().replace('.', ',') : ''},
                        // { field: 'Fonte', headerName: 'Fonte', minWidth: 100, editable: admin },
                        // { field: 'Ticker', headerName: 'Ticker', minWidth: 100, editable: admin },
                        // { field: 'FonteRating', headerName: 'Fonte Rating', minWidth: 100, editable: admin },
                        // { field: 'RatingFinal', headerName: 'Rating Final', minWidth: 100, editable: admin },
                        // { field: 'MesBaseRating', headerName: 'Mês Base', minWidth: 100, editable: admin },
                    ]}
                    rowHeigth="auto"
                    data={rowsUltimoNegocio}
                    setData={setRowsUltimoNegocio}
                    loadData={loadData}
                    disableDeleteColumn={!admin}
                    disableAddRow={!admin}
                    buttonAddVariant={"contained"}
                    buttonAddTitle={"Adicionar Rating"}
                />
            </>
        </ModalDialogPage>
    </>
}
UltimoPD.propTypes = {
    admin: PropTypes.bool,
    raiz_cnpj: PropTypes.object,
    value: PropTypes.string,
    loading: PropTypes.func,
    dadosEmpresa: PropTypes.object,
}

export default UltimoPD
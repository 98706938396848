import React from "react"
import { useState } from "react"
import PropTypes from 'prop-types'
import { TextField } from "@mui/material"

const EditableReceivableInput = ({ fieldValue, handleChange, editable=true, error }) => {
    const [isEditing, setIsEditing] = useState(fieldValue ? fieldValue.length === 0 : true)
    const [inputValue, setInputValue] = useState(fieldValue)

    const handleEditKeyUp = (event) => {
        if (inputValue === '') return
        if (event.keyCode !== 13) return
        handleChange(inputValue)
        setIsEditing(false)
    }

    const handleBlur = () => {
        if (inputValue === '') return
        handleChange(inputValue)
        setIsEditing(false)
    }

    const handleEditChange = (event) => {
        setInputValue(event.target.value)
    }

    return (
        (isEditing && editable) ? <TextField inputProps={{
            style: {
                height: "10px",
                width: "250px",
                fontSize: "0.875rem",
            },
        }}
            variant="standard"
            onKeyUp={handleEditKeyUp}
            onBlur={handleBlur}
            onChange={handleEditChange} value={inputValue} 
            error={error} // Adiciona a propriedade de erro
                helperText={error ? "Campo obrigatório" : ""} // Exibe mensagem se erro for verdadeiro
                sx={{
                    '& .MuiFormHelperText-root': {
                        color: error ? 'black' : 'transparent', // Cor do texto de erro em preto
                    },
                    '& .MuiInputBase-root' : {
                        borderColor: error ? 'red' : 'transparent', // Garantir que a borda fique vermelha
                    }
                }}
            /> : <div onClick={() => setIsEditing(true)}>{inputValue}</div>
    )
}

export default EditableReceivableInput

EditableReceivableInput.propTypes = {
    id: PropTypes.any,
    fieldValue: PropTypes.any,
    handleChange: PropTypes.func,
    editable: PropTypes.bool,
    error: PropTypes.bool
}

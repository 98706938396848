import SkeletonLine from "../../../../components/skeleton-line"
import {Button, Grid, TextField} from "@mui/material"
import ModalDialogPage from "../../../../components/modal-dialog"
import TableGray from "../../../../components/table/table-gray"
import React, {useEffect, useState} from "react"
import EmissaoService from "../../../../services/emissao-service"
import dayjs from "dayjs"
import {DATE_FORMAT, DATE_FORMAT_SHOW} from "../../../../utils/constants"
import PropTypes from "prop-types"
import ParameterTable from "../../../../components/table/parameter-table"
import {formatDecimalCaseReplaceComma, formatStringToFloatDecimalCase} from "../../../../utils/format-decimal"
import {ordenaPorDataReferencia} from "../../../../utils/date-format"
import {renderCellDate} from "../../../../utils/table-render-functions"

const defaultTable = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]


const UltimoPrecosProcessados = ({admin, value, loading, cetipIsin}) => {
    const emissaoService = new EmissaoService()
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const ultimoPrecosProcessadosDefault = {data_referencia: "", spread_credito: ""}
    const [loadingPrecosProcessados, setLoadingPrecosProcessados] = useState(false)
    const [ultimoPrecosProcessadosState, setUltimoPrecosProcessadosState] = useState(ultimoPrecosProcessadosDefault)
    const [rowsPrecosProcessados, setRowsPrecosProcessados] = useState([])
    const [table, setTable] = useState(defaultTable)

    useEffect(() => {
        console.log(admin)
        if (validateValue()) loadData(value)
    }, [value])

    const validateValue = () => {
        return value && value.length > 4
    }

    const loadData = (cetipIsin) => {
        setLoadingPrecosProcessados(true)
        emissaoService.getUltimoPrecosProcessados(cetipIsin).then((response) => {
            const {body} = response
            const {data} = body

            if (data !== null) {
                const {DataReferencia, SpreadCredito, PercentualPUPAR} = data

                setUltimoPrecosProcessadosState({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    spread_credito: formatDecimalCaseReplaceComma(SpreadCredito),
                    percentual_pupar: formatDecimalCaseReplaceComma(PercentualPUPAR),
                })
            } else {
                setUltimoPrecosProcessadosState(ultimoPrecosProcessadosDefault)
            }
        }).catch(() => {
            setLoadingPrecosProcessados(ultimoPrecosProcessadosDefault)
        }).finally(() => {
            setLoadingPrecosProcessados(false)
        })
    }

    const buildTable = () => {
        setTable(
            [
                {
                    cells: [`CETIP: ${cetipIsin.CETIP}`, `ISIN: ${cetipIsin.ISIN}`]
                },
            ]
        )
    }

    const openPrecosProcessadosModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        buildTable()
        loading(true)
        emissaoService.precosProcessados("get", `?CETIP=${value}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const {body} = response
                const {results} = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({...obj, id: index}))
                setRowsPrecosProcessados(rows)
            })
            .catch(() => {
            })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        let lastItem = rowsPrecosProcessados[0]

        if (rowsPrecosProcessados.length === 0) {
            lastItem = {
                "CETIP": value,
                "DataReferencia": dayjs().format(DATE_FORMAT),
                "TaxaMTM": 0,
                "PercentualPUPAR": 0,
                "SpreadCredito": 0
            }
        }

        loading(true)
        emissaoService.precosProcessados("post", lastItem)
            .then(() => {
                openPrecosProcessadosModal()
            })
            .catch(() => {
            })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const {ID} = row

        loading(true)
        emissaoService.precosProcessados("delete", ID)
            .then(() => {
                openPrecosProcessadosModal()
            })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const {ID} = newRow
        loading(true)
        emissaoService.precosProcessados("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    const formatValue = (params, minimum, maximum) => {
        const {value} = params

        return formatStringToFloatDecimalCase(value, minimum, maximum)
    }

    return <>
        {loadingPrecosProcessados ? <SkeletonLine/> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoPrecosProcessadosState.data_referencia}/>
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Spread Crédito" value={ultimoPrecosProcessadosState.spread_credito}/>
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="% PUPAR" value={ultimoPrecosProcessadosState.percentual_pupar}/>
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openPrecosProcessadosModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage
            open={openMostrarTodos}
            title="Histórico Taxa Indicativa"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table}/>
                    </Grid>
                </Grid>
                <br/>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <ParameterTable
                            columns={[
                                {
                                    field: 'DataReferencia',
                                    headerName: 'Data Referência',
                                    flex: 1,
                                    renderCell: renderCellDate,
                                    editable: admin
                                },
                                {
                                    field: 'TaxaMTM',
                                    headerName: 'Taxa MTM',
                                    flex: 1,
                                    editable: admin,
                                    renderCell: (value) => formatValue(value, 3, 3)
                                },
                                {
                                    field: 'PercentualPUPAR',
                                    headerName: 'Percentual PUPAR',
                                    flex: 1,
                                    editable: admin,
                                    renderCell: (value) => formatValue(value, 3, 3)
                                },
                                {
                                    field: 'SpreadCredito',
                                    headerName: 'Spread Crédito',
                                    flex: 1,
                                    editable: admin,
                                    renderCell: (value) => formatValue(value, 3, 3)
                                },
                            ]}
                            data={rowsPrecosProcessados}
                            setData={setRowsPrecosProcessados}
                            createRow={addRow}
                            deleteRow={deleteItem}
                            updateRow={updateRow}
                            loadData={loadData}
                            disableDeleteColumn={!admin}
                            disableAddRow={!admin}
                            buttonAddVariant={"contained"}
                            buttonAddTitle={"Adicionar Preços Processados"}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoPrecosProcessados.propTypes = {
    admin: PropTypes.bool,
    cetipIsin: PropTypes.object,
    value: PropTypes.string,
    loading: PropTypes.func,
}

export default UltimoPrecosProcessados
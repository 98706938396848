import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DATALAKE_CONTRAPARTE_PATH } from 'src/utils/api-routes'
import ParameterDatalakeContraparteTable from 'src/pages/parameters/parameters-datalake/tables/parameter-datalake-contraparte-table'

const TableContraparte = ({ loading, empresa }) => {
    const [filters, setFilters] = useState([])
    const [tableKey, setTableKey] = useState(Math.random())
    const PAGE_DEFAULT_SIZE = 25
    const PAGE_DEFAULT_INIT = 0
    const [pagination] = useState({ init: PAGE_DEFAULT_INIT, end: PAGE_DEFAULT_SIZE })

    useEffect(() => {
        loadFilter()
    }, [empresa])

    const loadFilter = () => {
        let newFilters = []

        if (empresa && empresa !== undefined) {
            newFilters.push({ field: 'CPFCNPJ', value: empresa })
        }

        console.log(filters)
        setFilters(newFilters)
        setTableKey(Math.random())
    }

    return <ParameterDatalakeContraparteTable
        key={tableKey}
        customFilter={false}
        filters={filters}
        endpoint={DATALAKE_CONTRAPARTE_PATH}
        loading={loading} 
        pagination={pagination}/>
}

TableContraparte.propTypes = {
    loading: PropTypes.func,
    empresa: PropTypes.object
}

export default TableContraparte
import { Button, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import PropTypes from 'prop-types'
import EmissaoService from "src/services/emissao-service"
import dayjs from "dayjs"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import SkeletonLine from "src/components/skeleton-line"
import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"
import { calculateMinWidth, renderCellDate, renderTextColumn } from "src/utils/table-render-functions"

const tableDefault = [
    {
        cells: ["Razão Social: ", "CNPJ: "]
    },
    {
        cells: ["Setor: ", "Subsetor:"]
    }
]

const UltimoRatingDevedor = ({ admin, cpfCnpj, dadosEmpresa, loading }) => {
    const ultimoRatingDevedorDefault = { data_validade: "", fonte: "", rating: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoRatingDevedor, setUltimoRatingDevedor] = useState(ultimoRatingDevedorDefault)
    const [rowsEmissao, setRowsEmissao] = useState([])
    const [loadingRatingDevedor, setLoadingRatingDevedor] = useState(false)
    const [table, setTable] = useState(tableDefault)

    useEffect(() => {
        if (validateValue()) loadData(cpfCnpj)
    }, [cpfCnpj])

    const validateValue = () => {
        if (cpfCnpj) {
            return true
        }

        return false
    }

    const loadData = (cpfCnpj) => {
        setLoadingRatingDevedor(true)
        emissaoService.getUltimoRatingDevedor(cpfCnpj).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataValidade, fonte, Rating } = data

                setUltimoRatingDevedor({
                    data_validade: dayjs(DataValidade).format(DATE_FORMAT_SHOW),
                    fonte: fonte,
                    rating: Rating,
                })
            } else {
                setUltimoRatingDevedor(ultimoRatingDevedorDefault)
            }
        }).catch(() => {
            setUltimoRatingDevedor(ultimoRatingDevedorDefault)
        }).finally(() => {
            setLoadingRatingDevedor(false)
        })
    }

    const buildTable = (setor, subsetor) => {
        setTable(
            [
                {
                    cells: [`Razão Social: ${dadosEmpresa.devedor}`, `CNPJ: ${dadosEmpresa.cpfCnpj}`]
                },
                {
                    cells: [`Setor: ${setor}`, `Subsetor: ${subsetor}`]
                }
            ]
        )
    }

    const openRatingDevedorModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        loading(true)
        emissaoService.ratingDevedor("get", `?CPFCNPJ=${cpfCnpj}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({ ...obj, id: index }))
                const { Setor, SubSetor } = rows[0]
                buildTable(Setor, SubSetor)
                setRowsEmissao(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const { ID } = row

        loading(true)
        emissaoService.ratingDevedor("delete", ID)
            .then(() => { openRatingDevedorModal() })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        let lastItem = rowsEmissao[0]
        
        if (rowsEmissao.length === 0) {
            lastItem = {
                "DataReferencia": dayjs().format(DATE_FORMAT),
                "CPFCNPJ": cpfCnpj,
                "DataValidade": dayjs().format(DATE_FORMAT),
                "Devedor": "N/A",
                "Setor": "N/A",
                "SubSetor": "N/A",
                "Fonte": "N/A",
                "ScoreRating": 0,
                "Rating": "N/A",
            }
        }
      
        loading(true)
        emissaoService.ratingDevedor("post", lastItem)
            .then(() => {
                openRatingDevedorModal()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const { ID } = newRow
        loading(true)
        emissaoService.ratingDevedor("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    const minWidthAnaliseCredito = calculateMinWidth(rowsEmissao, "AnaliseCreditoDevedor")
    const minWidthObservacao = calculateMinWidth(rowsEmissao, "Observacao")

    return <>
        {loadingRatingDevedor ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Validade" value={ultimoRatingDevedor.data_validade} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Fonte" value={ultimoRatingDevedor.fonte} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Rating" value={ultimoRatingDevedor.rating} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openRatingDevedorModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Histórico Rating Devedor"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={3} md={3} xl={3}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <ParameterTable
                            columns={[
                                { field: 'DataReferencia', minWidth: 130, headerName: 'Data Referencia', flex: 1, editable: admin, renderCell: renderCellDate },
                                { field: 'DataValidade', minWidth: 130, headerName: 'Data Validade', flex: 1, editable: admin, renderCell: renderCellDate },
                                { field: 'Fonte', minWidth: 100, headerName: 'Fonte', flex: 1, editable: admin, },
                                { field: 'ScoreRating', minWidth: 100, headerName: 'Score Rating', flex: 1, editable: admin, },
                                { field: 'Rating', minWidth: 100, headerName: 'Rating', flex: 1, editable: admin, },
                                { field: 'Sinal', minWidth: 100, headerName: 'Sinal', flex: 1, editable: admin, },
                                { field: 'Perspectiva', minWidth: 100, headerName: 'Perspectiva', flex: 1, editable: admin, },
                                { field: 'Observacao', headerName: 'Observação', flex: 1, minWidth: minWidthObservacao, editable: admin, renderCell: renderTextColumn },
                                { field: 'AnaliseCreditoDevedor', headerName: 'Análise Crédito Devedor', flex: 1, minWidth: minWidthAnaliseCredito, editable: admin, renderCell: renderTextColumn },
                            ]}
                            rowHeigth="auto"
                            data={rowsEmissao}
                            setData={setRowsEmissao}
                            createRow={addRow}
                            deleteRow={deleteItem}
                            updateRow={updateRow}
                            loadData={loadData}
                            disableDeleteColumn={!admin}
                            disableAddRow={!admin}
                            buttonAddVariant={"contained"}
                            buttonAddTitle={"Adicionar Rating"}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoRatingDevedor.propTypes = {
    admin: PropTypes.bool,
    cpfCnpj: PropTypes.string,
    loading: PropTypes.func,
    dadosEmpresa: PropTypes.object,
}

export default UltimoRatingDevedor
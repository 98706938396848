export const INDEX_PAGE = "/"
export const WITHOUT_PERMISSION_PAGE = "/solicitar-acesso"
export const RECOVER_PASSWORD_PAGE = "/recuperar-senha"
export const REGISTER_PASSWORD_PAGE = "/register-password"
export const HOME_PAGE = "/home"
export const CLIENT_PAGE = "/clientes"
export const USER_PAGE = "/usuarios"
export const LOGOUT_PAGE = "/logout"
export const PROFILE_PAGE = "/perfil"
export const PRODUCT_PAGE = "/produtos"
export const FIXED_INCOME_CALCULATOR_PAGE = "/calculadora-renda-fixa"

export const CURVES_PAGE = "/curvas"
export const CURVES_TIR_PAGE = "/curvas-ettjc" //mudado 16/07
export const CURVES_SPREAD_PAGE = "/curvas-spread"
export const CURVES_SETORIAL_PAGE = "/curvas-setoriais"
export const CURVES_SUBSETORIAL_PAGE = "/curvas-subsetoriais"
export const CURVES_DTS_PAGE = "/curvas-dts"

export const PRICES_PAGE = "/precos"
export const PRICES_PU_PAGE = "/precos-pu"
export const PRICES_TIR_PAGE = "/precos-tir"
export const PRICES_PUPAR_PAGE = "/precos-pupar"
export const CUSTOMERS_PRICE_LOG_PAGE = "/precos-log"
export const PRICES_UPDATE_SPREADS = "/atualizacao-spreads"
export const PRICES_CHARACTERISTICS = "/caracteristicas"
export const PRICES_CALCULATION_CETIP_PAGE = "/calculo-cetip"

export const IMPORT_PAGE = "/importacao"

export const CONCESSIONS_PAGE = "/concessoes"
export const WALLETS_PAGE = "/carteiras"
export const SIMULADOR_SUBORDINACAO_PAGE = "/simulador-subordinacao"
export const MARKET_RATES_PAGE = "/taxas-mercado"
export const PARAMETERS_PAGE = "/parametrosGR"
export const PARAMETERS_CALC_PAGE = "/parametros-calculo"
export const PARAMETERS_DATALAKE_PAGE = "/consulta-dados"
export const METHODOLOGY_PAGE = "/metodologia"
export const ROUTINES_PAGE = "/rotinas"
export const ROUTINES_HOME_PAGE = "/rotinas-workflow"
export const INTEGRATION_WORKFLOW_PAGE = "/integracao-workflow"
export const INTEGRATION_PAGE = "/integracao"
export const INTEGRATION_DATA_PAGE = "/integracao-dados-externos"
export const REPORT_DATA_WORKFLOW_PAGE = "/report-data-workflow"

export const DELETE_DATA_PAGE = "/exclusao-dados"
export const EMISSAO_PAGE = "/emissao"
export const API_INTEGRATION_PAGE = "/api-integracao"
export const EMPRESAS_PAGE = "/empresas"


export const ACCESS_PAGE = "/acessos"
export const SUPPORT_PAGE = "/suporte"

export const RATER_CALCULADORA_PAGE="/rater-calculadora"
export const RATER_HISTORICO_PAGE="/rater-historico"
export const RATER_MERTON_PAGE="/rater-merton"
export const RATER_RELATORIO_PAGE="/rater-relatorio"
export const RATER_SETORIAL_PAGE="/rater-setorial"
export const RATER_CONFIGURACOES_PAGE="/rater-configuracoes"

import SkeletonLine from "../../../../components/skeleton-line"
import {Button, Grid, TextField} from "@mui/material"
import ModalDialogPage from "../../../../components/modal-dialog"
import TableGray from "../../../../components/table/table-gray"
import React, {useEffect, useState} from "react"
import EmissaoService from "../../../../services/emissao-service"
import dayjs from "dayjs"
import {DATE_FORMAT, DATE_FORMAT_SHOW} from "../../../../utils/constants"
import PropTypes from "prop-types"
import ParameterTable from "../../../../components/table/parameter-table"
import {formatDecimalCaseReplaceComma} from "../../../../utils/format-decimal"
import {ordenaPorDataReferencia} from "../../../../utils/date-format"
import {renderCellDate} from "../../../../utils/table-render-functions"

const defaultTable = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]


const UltimoIndiceLiquidez = ({admin, value, loading, cetipIsin}) => {
    const emissaoService = new EmissaoService()
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const ultimoEventoLiquidezDefault = {data_referencia: "", indice_liquidez: ""}
    const [loadingIndiceLiquidez, setLoadingIndiceLiquidez] = useState(false)
    const [ultimoIndiceLiquidezState, setUltimoIndiceLiquidezState] = useState(ultimoEventoLiquidezDefault)
    const [rowsIndiceLiquidez, setRowsIndiceLiquidez] = useState([])
    const [table, setTable] = useState(defaultTable)

    useEffect(() => {
        console.log(admin)
        if (validateValue()) loadData(value)
    }, [value])

    const validateValue = () => {
        return value && value.length > 4
    }

    const loadData = (cetipIsin) => {
        setLoadingIndiceLiquidez(true)
        emissaoService.getUltimoIndiceLiquidez(cetipIsin).then((response) => {
            const {body} = response
            const {data} = body

            if (data !== null) {
                const {DataReferencia, IndiceLiquidez} = data

                setUltimoIndiceLiquidezState({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    indice_liquidez: formatDecimalCaseReplaceComma(IndiceLiquidez)
                })
            } else {
                setUltimoIndiceLiquidezState(ultimoEventoLiquidezDefault)
            }
        }).catch(() => {
            setLoadingIndiceLiquidez(ultimoEventoLiquidezDefault)
        }).finally(() => {
            setLoadingIndiceLiquidez(false)
        })
    }

    const buildTable = () => {
        setTable(
            [
                {
                    cells: [`CETIP: ${cetipIsin.CETIP}`, `ISIN: ${cetipIsin.ISIN}`]
                },
            ]
        )
    }

    const openIndiceLiquidezModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        buildTable()
        loading(true)
        emissaoService.indiceLiquidez("get", `?CETIP=${value}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const {body} = response
                const {results} = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({...obj, id: index}))
                setRowsIndiceLiquidez(rows)
            })
            .catch(() => {
            })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        let lastItem = rowsIndiceLiquidez[0]

        if (rowsIndiceLiquidez.length === 0) {
            lastItem = {
                "CETIP": value,
                "DataReferencia": dayjs().format(DATE_FORMAT),
                "IndiceLiquidez": 0
            }
        }

        loading(true)
        emissaoService.indiceLiquidez("post", lastItem)
            .then(() => {
                openIndiceLiquidezModal()
            })
            .catch(() => {
            })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const {ID} = row

        loading(true)
        emissaoService.indiceLiquidez("delete", ID)
            .then(() => {
                openIndiceLiquidezModal()
            })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const {ID} = newRow
        loading(true)
        emissaoService.indiceLiquidez("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    return <>
        {loadingIndiceLiquidez ? <SkeletonLine/> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={5} sm={12} xl={5}>
                    <TextField fullWidth label="Data Referência" value={ultimoIndiceLiquidezState.data_referencia}/>
                </Grid>
                <Grid item xs={12} md={5} sm={12} xl={5}>
                    <TextField fullWidth label="Índice Liquidez" value={ultimoIndiceLiquidezState.indice_liquidez}/>
                </Grid>
                <Grid item xs={12} md={2} sm={12} xl={2}>
                    <Button fullWidth variant="contained" onClick={openIndiceLiquidezModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage
            open={openMostrarTodos}
            title="Histórico Índice Liquidez"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table}/>
                    </Grid>
                </Grid>
                <br/>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <ParameterTable
                            columns={[
                                {field: 'DataReferencia', headerName: 'Data Referência', flex: 1, renderCell: renderCellDate},
                                {field: 'IndiceLiquidez', headerName: 'Índice Liquidez', flex: 1},
                            ]}
                            data={rowsIndiceLiquidez}
                            setData={setRowsIndiceLiquidez}
                            createRow={addRow}
                            deleteRow={deleteItem}
                            updateRow={updateRow}
                            loadData={loadData}
                            disableDeleteColumn={!admin}
                            disableAddRow={!admin}
                            buttonAddVariant={"contained"}
                            buttonAddTitle={"Adicionar Índice Liquidez"}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoIndiceLiquidez.propTypes = {
    admin: PropTypes.bool,
    cetipIsin: PropTypes.object,
    value: PropTypes.string,
    loading: PropTypes.func,
}

export default UltimoIndiceLiquidez
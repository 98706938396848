import {  ROUTINE_RISKFREE_WORKFLOW_PATH, ROUTINE_LIQUIDEZ_WORKFLOW_PATH, ROUTINE_SPREAD_RELATIVO_WORKFLOW_PATH, ROUTINE_PRECOS_PROCESSADOS_WORKFLOW_PATH, ROUTINE_VOLATILIDADE_WORKFLOW_PATH, ROUTINE_CLIENTE_PRECOS_LOG_WORKFLOW_PATH, ROUTINE_PRECOS_EVENTOS_FINANCEIROS_WORKFLOW_PATH, ROUTINE_SPREADS_BRUTOS_TRATADOS_WORKFLOW_PATH,  ROUTINE_SPREADSEMISSAO_WORKFLOW_PATH, ROUTINE_SPREADS_NEGOCIADOS_WORKFLOW_PATH } from "src/utils/api-routes"

export const routineDataWorkflowList = [
    //{ value: "CalculationCetip", description: "Calculo Cetip", disabled: true },
    { value: "ClientePrecosLogWorkflow", description: "Cliente Precos Log Workflow" },
    //{ value: "Curva", description: "Curva", disabled: true },
    //{ value: "EventosFinanceirosPupar", description: "Eventos Financeiros Pupar", disabled: true },
    //{ value: "EventosFinanceirosPumtm", description: "Eventos Financeiros Pumtm", disabled: true },
    //{ value: "Liquidez", description: "Liquidez", disabled: true },
    { value: "LiquidezWorkflow", description: "Liquidez Workflow" },
    //{ value: "Precos", description: "Preços", disabled: true },
    //{ value: "PrecosAndados", description: "Preços Andados", disabled: true },
    { value: "PrecosEventosFinanceirosWorkflow", description: "Precos Eventos Financeiros Workflow" },
    { value: "PrecosProcessadosWorkflow", description: "Precos Processados Workflow" },
    //{ value: "PrecosTunel", description: "Túnel de Preços", disabled: true },
    { value: "SpreadsBrutosTratadosWorkflow", description: "Spreads Brutos Tratados Workflow" },
    { value: "SpreadEmissaoWorkflow", description: "Spread Emissão Workflow" },
    { value: "SpreadNegociadoWorkflow", description: "Spread Negociado Workflow"},
    //{ value: "SpreadRelativo", description: "Spread Relativo", disabled: true },
    { value: "SpreadRelativoWorkflow", description: "Spread Relativo Workflow" },
    { value: "TratamentoRiskFree", description: "Tratamento Risk Free" },
    { value: "VolatilidadeCurvasWorkflow", description: "Volatilidade de Curvas Workflow" }
]

export const routineEndpointWorkflowList = [
    // { value: "SpreadRelativo", endpoint: ROUTINE_SPREAD_RELATIVO_PATH, disabled: true},
    { value: "VolatilidadeCurvasWorkflow", endpoint: ROUTINE_VOLATILIDADE_WORKFLOW_PATH },
    // { value: "Curva", endpoint: ROUTINE_CURVA_PATH, disabled: true },
    // { value: "Liquidez", endpoint: ROUTINE_LIQUIDEZ_PATH, disabled: true },
    // { value: "Precos", endpoint: ROUTINE_PRECOS_PATH, disabled: true },
    // { value: "PrecosTunel", endpoint: ROUTINE_PRECOS_TUNEL_PATH, disabled: true },
    // { value: "PrecosAndados", endpoint: ROUTINE_PRECOS_ANDADOS_PATH, disabled: true },
     { value: "SpreadsBrutosTratadosWorkflow", endpoint: ROUTINE_SPREADS_BRUTOS_TRATADOS_WORKFLOW_PATH },
    { value: "SpreadEmissaoWorkflow", endpoint: ROUTINE_SPREADSEMISSAO_WORKFLOW_PATH },
    { value: "SpreadNegociadoWorkflow", endpoint: ROUTINE_SPREADS_NEGOCIADOS_WORKFLOW_PATH },
    { value: "TratamentoRiskFree", endpoint: ROUTINE_RISKFREE_WORKFLOW_PATH },
    // { value: "EventosFinanceirosPupar", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH, disabled: true },
    // { value: "EventosFinanceirosPumtm", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH, disabled: true },
    // { value: "CalculationCetip", endpoint: PRICES_CALCULATION_CETIP_PATH, disabled: true },
    { value: "ClientePrecosLogWorkflow", endpoint: ROUTINE_CLIENTE_PRECOS_LOG_WORKFLOW_PATH },
    { value: "LiquidezWorkflow", endpoint: ROUTINE_LIQUIDEZ_WORKFLOW_PATH },
    { value: "SpreadRelativoWorkflow", endpoint: ROUTINE_SPREAD_RELATIVO_WORKFLOW_PATH },
    { value: "PrecosProcessadosWorkflow", endpoint: ROUTINE_PRECOS_PROCESSADOS_WORKFLOW_PATH },
    { value: "PrecosEventosFinanceirosWorkflow", endpoint: ROUTINE_PRECOS_EVENTOS_FINANCEIROS_WORKFLOW_PATH },

    
]
import { Autocomplete, Button, CircularProgress, DialogActions, Grid, TextField, Link, Typography,Box,Divider, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import React, { useState } from "react"
import PropTypes from 'prop-types'
import Loading from "src/components/loading"
import DatalakeService from "src/services/datalake-service"
import TextFieldFormatCurrency from "src/components/text-field-format-currency"
import { formatTimestamp } from "src/utils/format-date"
import TableFormatterService from "src/pages/rater/subpages/components/tableFormatter"
import { useDropzone } from "react-dropzone"
import AlertMessage from "src/components/alert-message"


const inputs = [
    { label: "CNPJ", value: "CPFCNPJ", required: true },
    { label: "Raiz CNPJ", value: "RaizCNPJ", required: true },
    { label: "Razão Social", value: "RazaoSocial", required: true },
    { label: "Nome Fantasia", value: "NomeFantasia", required: true },
    { label: "Mês Fechamento", value: "MesFechamento", required: false,defaultValue: "12" },
    { label: "Observação", value: "Observacao", required: false },
    { label: "Atividade", value: "Atividade", required: false },
    { label: "Site RI", value: "Site_RI", required: false },
    { label: "Grupo Auditoria", value: "GrupoAuditoria", required: false, type: "number"  },
    { label: "Grupo Custodiante", value: "GrupoCustodiante", required: false, type: "number"  },
    { label: "Grupo Coordenador Líder", value: "GrupoCoordenadorLider", required: false, type: "number"  },
    { label: "Grupo Fiduciário", value: "GrupoFiduciario", required: false, type: "number"  },
    { label: "Grupo IF Recebedora", value: "GrupoIFRecebedora", required: false, type: "number"  },
    { label: "Grupo Securitizador", value: "GrupoSecuritizador", required: false, type: "number"  },
    //{ label: "Periodicidade Balanço", value: "PeriodicidadeBalanco", required: false, type: "number"  },
]

const inputDrop = [
    { label: "Tipo Cadastro", value: "TipoCadastro"},
    { label: "Periodicidade Balanço", value: "PeriodicidadeBalanco"},
]

const inputsTextArea = [//Bate em Calc_Setorial
    { label: "Setor", value: "Setor", required: true },
    { label: "SubSetor", value: "SubSetor", required: true },
]

const inputsDoubleLine = [//Bate em contraparte
    { label: "Grupo Econômico", value: "GrupoEconomico_Nome" },
    { label: "Raiz CNPJ  Grupo Econômico", value: "GrupoEconomico_RaizCNPJ"},
]

const optionsMap = {
    "Tipo Cadastro": ["MATRIZ", "FILIAL"],
    "Periodicidade Balanço": ["Indeterminado", "Anual", "Semestral", "Trimestral", "Bimestral"]
}

const RegisterEmpresa = ({ afterRegister }) => {
    const calcService = new DatalakeService()
    const tableFormatter = new TableFormatterService()
    const [selectedFile, setSelectedFile] = useState(null)
    const [valueBody, setValueBody] = useState(() => {
        // Inicializa o valor de valueBody com defaultValue
        const initialValues = {}
        inputs.forEach(input => {
            initialValues[input.value] = input.defaultValue || "" // Usa o defaultValue, se existir
        })
        return initialValues
    })
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const [EmpresasList, setEmpresasList] = useState([])
    const [loading, setLoading] = useState(false)
    const [fieldData, setFieldData] = useState(
        inputsDoubleLine.reduce((acc, input) => {
            acc[input.value] = {
                loading: false,
                options: [],
            }
            return acc
        }, {})
    )

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        setSelectedFile(file)
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })



    const loadData = () => {
        calcService.loadList().then((response) => {
            const { body } = response
            const { results } =  body
            
            const list = results
                .filter((obj) => obj.Setor !== "Consórcio")
                .map((obj, index) => ({
                    id: index, // Mantendo um ID para identificação
                    nomeFantasia: obj.NomeFantasia, 
                    grupoEconomico: obj.GrupoEconomico_Nome,
                    grupoEconomicoRaiz_CNPJ: obj.GrupoEconomico_RaizCNPJ
                }))
            setEmpresasList(list)
        })
        .catch((error) => {
            loading(false)
            console.error("Erro ao buscar dados:", error)
            setEmpresasList([])
        })
    }

    const verificarCamposObrigatorios = (valuebody) => {
        const camposObrigatorios = [
            { label: "CNPJ", value: "CPFCNPJ", required: true },
            { label: "Raiz CNPJ", value: "RaizCNPJ", required: true },
            { label: "Razão Social", value: "RazaoSocial", required: true },
            { label: "Nome Fantasia", value: "NomeFantasia", required: true },
            { label: "Setor", value: "Setor", required: true },
            { label: "SubSetor", value: "SubSetor", required: true },
        ]
    
        // Verifica se os campos obrigatórios estão no objeto valuebody
        for (const campo of camposObrigatorios) {
            if (campo.required && (!Object.prototype.hasOwnProperty.call(valuebody, campo.value) || valuebody[campo.value].trim() === "")) {
                return `Campo obrigatório "${campo.label}" não está preenchido.`
            }
        }
    
        return null 
    }

    const verificacontraparte= async (body) => {
        const { CPFCNPJ } = body

        try {

            const response = await calcService.verificacontraparte(CPFCNPJ)

            const { count } = response.body  
            if (count > 0) {
                afterRegister("Versão do CPFCNPJ já existe", "error")
            }
            return Number(count)  

        }catch (error) {

            console.error(" Erro ao verificar a contraparte:", error)
            setValueBody(body)
            return 0  
            
        }


    }

    const loadSetorInfo = async (inputForm, input, value) => {
        setFieldData((prev) => ({
            ...prev,
            [inputForm]: { ...prev[inputForm], loading: true },
        }))


        calcService.recuperaDadosSetor(input, value)
            .then((response) => {
                const { body } = response
                const { results } = body
                const inputResults = results
                    .map(item => item[input])
                    .filter((value, index, self) => self.indexOf(value) === index)

                setFieldData((prev) => ({
                    ...prev,
                    [inputForm]: { ...prev[inputForm], options: inputResults, loading: false },
                }))
            })
            .catch((error) => {
                console.error("Error:", error)
            })
            .finally(() => {
                setFieldData((prev) => ({
                    ...prev,
                    [inputForm]: { ...prev[inputForm], loading: false },
                }))
            })
    }


    const onChangeInputLoadSetor = (event) => {
        const { target } = event
        const inputValue = target.value
        const inputName = target.name

        if (inputName === "Setor" ) {
            console.log("Carregando Setor...")
            loadSetorInfo(inputName, "Setor", inputValue)
        } else {
            loadSetorInfo(inputName, "SubSetor", inputValue)
        }

        onChangeInput(event)
    }

    const onChangeInput = (event) => {
        const { name, value } = event.target
    
        setValueBody((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const getGrupoEconomico = (nomeFantasia, empresasList) => {
        const empresa = empresasList.find(emp => emp.nomeFantasia === nomeFantasia)
        
        if (empresa) {
            return {
                grupoEconomico: empresa.grupoEconomico,
                grupoEconomicoRaiz_CNPJ: empresa.grupoEconomicoRaiz_CNPJ
            }
        }
    
        return {
            grupoEconomico: "",
            grupoEconomicoRaiz_CNPJ: ""
        }
    }


    const registerCaracteristicas = async () => {
        
        try {
            
            const erroObrigatorio = verificarCamposObrigatorios(valueBody)
            if (erroObrigatorio) {
                openAlert(erroObrigatorio, "error")
                return
            }
            const count = await verificacontraparte(valueBody)
            console.log("count", count)
            if (count > 0) {

                return 
            }
            setLoading(true)
            console.log(valueBody)
    
            const response = await calcService.registerContra(valueBody)
            openAlert("Dados inseridos com sucesso.", "success")
            if (response) {
                console.log("Registro realizado com sucesso.")
            }
    
        } catch (err) {
            handleError(err, "Erro ao registrar empresa.")
        } finally {
            setLoading(false)
        }
    }
    
    const handleError = (err, defaultMessage) => {
        try {
            const { response: { body: { error } = {}, text } = {} } = err

            const errorMessage = error || text || defaultMessage
            afterRegister(errorMessage, "error")
        } catch (parseError) {
            afterRegister(defaultMessage, "error")
        }
    }

    const handleUpload = () => {
        if (selectedFile) {
            let fileType = ""
    
            if (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                fileType = "xlsx"
            } else if (selectedFile.type === "text/csv") {
                fileType = "csv"
            } else {
                openAlert("Tipo de arquivo não suportado.", "error")
                return
            }
        
        if (selectedFile) {
            calcService.sendFile(selectedFile, fileType).then(() => {
                openAlert("Dados inseridos com sucesso.", "success")
            }).catch((error) => {
                console.debug(error)
                openAlert("Houve um erro ao enviar o arquivo, tente novamente.", "error")
            })
            .finally(() => {
            })
        }
    }}

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    return (
        <>
            <Loading show={loading} />
            {/* inputs */}
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
                {
                    inputs.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                                {input.type !== "number" ?
                                    <TextField
                                        fullWidth
                                        label={input.label}
                                        value={valueBody[input.value] || ""}
                                        defaultValue={input.defaultValue || ""}
                                        name={input.value}
                                        id={input.type ? input.type : "text"}
                                        required={input.required}
                                        inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                        onChange={onChangeInput}
                                        error={input.required && !valueBody[input.value]}  // Verifica se o campo é obrigatório e está vazio
                                        helperText={input.required && !valueBody[input.value] ? "Este campo é obrigatório" : ""}
                                    />
                                    :
                                    <TextFieldFormatCurrency
                                        label={input.label}
                                        variant="outlined"
                                        key={input.label}
                                        value={valueBody[input.value] || ""}
                                        onChange={(value) => onChangeInput({
                                            target: {
                                                name: input.value,
                                                id: "number",
                                                value: value,
                                            },
                                        })}
                                        error={input.required && !valueBody[input.value]}  // Verifica se o campo é obrigatório e está vazio
                                        helperText={input.required && !valueBody[input.value] ? "Este campo é obrigatório" : ""}
                                    />
                                }
                        </Grid>
                    ))
                }
            </Grid>

            {/* double line */}
            <br />
            <Loading show={loading} />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputsDoubleLine.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                                <Autocomplete
                                    inputValue={valueBody[input.value] || ""}  // Usando o valor do input correspondente
                                    freeSolo
                                    disablePortal
                                    options={EmpresasList.map(emp => emp.nomeFantasia)} 
                                    onOpen={() => {
                                        if (EmpresasList.length === 0) loadData()  
                                    }}
                                    onChange={(event, newValue) => {
                                        if (!newValue) {
                                            
                                            setValueBody(prev => ({
                                                ...prev,
                                                [input.value]: "",                      
                                                GrupoEconomico_Nome: "",               
                                                GrupoEconomico_RaizCNPJ: ""            
                                            }))
                                            return
                                        }
                                
                                        
                                        const { grupoEconomico, grupoEconomicoRaiz_CNPJ } = getGrupoEconomico(newValue, EmpresasList)
                                
                                        
                                        setValueBody(prev => ({
                                            ...prev,
                                            [input.value]: newValue,                    
                                            GrupoEconomico_Nome: grupoEconomico,         // Atualiza Grupo Econômico
                                            GrupoEconomico_RaizCNPJ: grupoEconomicoRaiz_CNPJ // Atualiza Grupo Econômico Raiz CNPJ
                                        }))
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            multiline
                                            id={input.type ? input.type : "text"}
                                            rows={1}
                                            name={input.value}
                                            label={input.label}
                                            required={input.required}
                                            error={input.required && (!valueBody[input.value] || valueBody[input.value].trim() === "")}  // Validação de erro
                                            helperText={input.required && (!valueBody[input.value] || valueBody[input.value].trim() === "") ? "Este campo é obrigatório" : ""}  // Mensagem de erro
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading && (
                                                            <CircularProgress color="primary" size={20} />
                                                        )}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                    noOptionsText={loading ? "Carregando opções..." : "Nenhum resultado encontrado"} // Mensagem enquanto carrega
                                />
                        </Grid>
                    ))
                }
                
            </Grid>

            {/* text area */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {inputsTextArea.map((input) => (
                    <Grid item xs={12} md={6} xl={3} key={input.label}>
                                <Autocomplete
                                    freeSolo
                                    inputValue={valueBody[input.value] || ""}  
                                    name={input.value}
                                    options={fieldData[input.value]?.options || []} 
                                    loading={fieldData[input.value]?.loading ?? false} 
                                    onInputChange={(_, newValue) => {
                                        // Atualiza o valor digitado no campo
                                        setValueBody((prev) => ({
                                            ...prev,
                                            [input.value]: newValue, 
                                        }))

                                        
                                        onChangeInputLoadSetor({ target: { name: input.value, value: newValue } })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label={input.label}
                                            name={input.value}
                                            required={input.required}
                                            error={input.required && (!valueBody[input.value] || valueBody[input.value].trim() === "")}  // Validação de erro
                                            helperText={input.required && (!valueBody[input.value] || valueBody[input.value].trim() === "") ? "Este campo é obrigatório" : ""}  // Mensagem de erro
                                            multiline
                                            rows={1}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {fieldData[input.value]?.loading && (
                                                            <CircularProgress color="primary" size={20} />
                                                        )}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            onChange={(e) => {
                                                // Captura o valor digitado e atualiza o estado em tempo real
                                                const newValue = e.target.value
                                                setValueBody((prev) => ({
                                                    ...prev,
                                                    [input.value]: newValue, // Atualiza o estado com o novo valor
                                                }))

                                                // Chama a função para carregar as opções dinamicamente
                                                onChangeInputLoadSetor({ target: { name: input.value, value: newValue } })
                                            }}
                                        />
                                    )}
                                />
                    </Grid>
                ))}
            </Grid>

            {/* droplist */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
                {
                    inputDrop.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            <FormControl fullWidth variant="outlined" error={input.required && !valueBody[input.value]}>
                                <InputLabel>{input.label}</InputLabel>
                                <Select
                                    value={valueBody[input.value] || ""}
                                    onChange={onChangeInput}
                                    name={input.value}
                                    required={input.required}
                                    label={input.label}
                                >
                                    {optionsMap[input.label]?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ))
                }
            </Grid>

            <br />
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={registerCaracteristicas}>Cadastrar</Button>
            </DialogActions>

            <Grid item sx={{ display: 'flex' }}>
                <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Grid item>
                        Caso queira cadastrar em lote, baixe o arquivo de <Link href="/example/ExemploContraparte.xlsx" >Exemplo</Link> e faça o upload abaixo:
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} >
                        <div {...getRootProps({ className: 'dropzone',style: {
                                    border: '2px dashed #222851', 
                                    borderRadius: '8px', 
                                    padding: '20px', 
                                    textAlign: 'center', 
                                    cursor: 'pointer', 
                                    color: '#222851',
                                }, })} >
                            <input {...getInputProps()} />
                            <span>ARRASTE E SOLTE O ARQUIVO AQUI OU CLIQUE PARA SELECIONAR</span>
                        </div>
                    </Grid>
                    {selectedFile ?
                        <Grid item xs={11} md={6} xl={6} sx={{ ml: 'auto', mt: -4 }}>
                            <Typography variant="subtitle" gutterBottom>Nome: <b>{selectedFile.name.substring(0, 40)}</b></Typography>
                            <Divider />
                            <Typography variant="subtitle" gutterBottom>Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b></Typography>
                            <Divider />
                            <Typography variant="subtitle" gutterBottom>Tamanho do Arquivo (MB): <b>{tableFormatter.getFileSize(selectedFile.size)}</b></Typography>
                            <Divider />
                            <Typography variant="subtitle" gutterBottom>Tipo: <b>{selectedFile.type}</b></Typography>
                            <Divider />
                        </Grid>
                        : null}
                </Grid>
            </Grid>
        <Grid item  sx={{ marginTop: '16px' }}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
                <Button
                    onClick={() => {
                        handleUpload()      
                    }}
                    variant="outlined"
                    sx={{
                        backgroundColor: selectedFile ? '#222851' : '#B0B0B0', // Fica cinza quando desabilitado
                        color: selectedFile ? '#fff' : '#888', // Cor do texto fica mais clara quando desabilitado
                        borderColor: selectedFile ? '#007BFF' : '#B0B0B0', // Cor da borda fica cinza
                        '&:hover': {
                            backgroundColor: selectedFile ? "#002244" : '#B0B0B0', // Cor de hover ajustada
                        },
                    }}
                    disabled={!selectedFile} 
                    >UPLOAD
                </Button>
                    {selectedFile ?
                        <Button 
                                    variant="outlined" 
                                    color="error" 
                                    onClick={() => setSelectedFile(null)} 
                                    sx={{ marginTop: '0px' }}
                                >Remover Arquivo
                        </Button>
                    : null}
            </Box>
        </Grid>


        </>
    )
}

RegisterEmpresa.propTypes = {
    afterRegister: PropTypes.func,
}

export default RegisterEmpresa
import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import DatalakeService from "src/services/datalake-service"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"

const ParameterDatalakeCVMCadastroEmpresasTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        if (props.filters && props.filters.length > 0) {
            onFilter(props.filters, 25, 0)
            return
        }
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "CPFCNPJ": "N/A",
            "TipoFiscal": "N/A",
            "RazaoSocial": "N/A",
            "NomeFantasia": "N/A",
            "PaisOrigem": "N/A",
            "DataRegistroCVM": dayjs().format(DATE_FORMAT),
            "DataConstituicao": dayjs().format(DATE_FORMAT),
            "CodigoCVM": "N/A",
            "SetorCVM": "N/A",
            "TipoMercado": "N/A",
            "ControleAcionario": "N/A",
            "TipoEndereco": "N/A",
            "Logradouro": "N/A",
            "Complemento": "N/A",
            "Bairro": "N/A",
            "Municipio": "N/A",
            "UF": "N/A",
            "Pais": "N/A",
            "CEP": "N/A",
            "DDITelefone": "N/A",
            "DDDTelefone": "N/A",
            "Telefone": "N/A",
            "DDIFax": "N/A",
            "DDDFax": "N/A",
            "Fax": "N/A",
            "Email": "N/A",
            "CargoResponsavel": "N/A",
            "Responsavel": "N/A",
            "DataResponsavel": dayjs().format(DATE_FORMAT),
            "LogradouroResponsavel": "N/A",
            "ComplementoResponsavel": "N/A",
            "BairroResponsavel": "N/A",
            "MunicipioResponsavel": "N/A",
            "UFResponsavel": "N/A",
            "PaisResponsavel": "N/A",
            "CEPResponsavel": "N/A",
            "DDDTelefoneResponsavel": "N/A",
            "TelefoneResponsavel": "N/A",
            "DDDFaxResponsavel": "N/A",
            "FaxResponsavel": "N/A",
            "EmailResponsavel": "N/A",
            "CNPJAuditor": "N/A",
            "Auditor": "N/A",
        }

        console.log("DataConstituicao:", body.DataConstituicao)
        console.log("DataResponsavel:", body.DataResponsavel)

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CPFCNPJ, TipoFiscal, RazaoSocial, NomeFantasia, PaisOrigem, DataRegistroCVM, DataConstituicao, CodigoCVM, SetorCVM, TipoMercado,  ControleAcionario, TipoEndereco, Logradouro, Complemento, Bairro, Municipio, UF, Pais, CEP, DDITelefone, DDDTelefone, Telefone, DDIFax, DDDFax, Fax, Email, CargoResponsavel, Responsavel, DataResponsavel, LogradouroResponsavel, ComplementoResponsavel, BairroResponsavel, MunicipioResponsavel, UFResponsavel, PaisResponsavel, CEPResponsavel, DDDTelefoneResponsavel, TelefoneResponsavel, DDDFaxResponsavel, FaxResponsavel, EmailResponsavel, CNPJAuditor, Auditor
        } = newRow

        const body = {
            "CPFCNPJ": CPFCNPJ,
            "TipoFiscal": TipoFiscal,
            "RazaoSocial": RazaoSocial,
            "NomeFantasia": NomeFantasia,
            "PaisOrigem": PaisOrigem,
            "DataRegistroCVM": DataRegistroCVM,
            "DataConstituicao": DataConstituicao,
            "CodigoCVM": CodigoCVM,
            "SetorCVM": SetorCVM,
            "TipoMercado": TipoMercado,
            "ControleAcionario": ControleAcionario,
            "TipoEndereco": TipoEndereco,
            "Logradouro": Logradouro,
            "Complemento": Complemento,
            "Bairro": Bairro,
            "Municipio": Municipio,
            "UF": UF,
            "Pais": Pais,
            "CEP": CEP,
            "DDITelefone": DDITelefone,
            "DDDTelefone": DDDTelefone,
            "Telefone": Telefone,
            "DDIFax": DDIFax,
            "DDDFax": DDDFax,
            "Fax": Fax,
            "Email": Email,
            "CargoResponsavel": CargoResponsavel,
            "Responsavel": Responsavel,
            "DataResponsavel": DataResponsavel,
            "LogradouroResponsavel": LogradouroResponsavel,
            "ComplementoResponsavel": ComplementoResponsavel,
            "BairroResponsavel": BairroResponsavel,
            "MunicipioResponsavel": MunicipioResponsavel,
            "UFResponsavel": UFResponsavel,
            "PaisResponsavel": PaisResponsavel,
            "CEPResponsavel": CEPResponsavel,
            "DDDTelefoneResponsavel": DDDTelefoneResponsavel,
            "TelefoneResponsavel": TelefoneResponsavel,
            "DDDFaxResponsavel": DDDFaxResponsavel,
            "FaxResponsavel": FaxResponsavel,
            "EmailResponsavel": EmailResponsavel,
            "CNPJAuditor": CNPJAuditor,
            "Auditor": Auditor
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoFiscal',
            headerName: 'TipoFiscal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RazaoSocial',
            headerName: 'RazaoSocial',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'NomeFantasia',
            headerName: 'NomeFantasia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PaisOrigem',
            headerName: 'PaisOrigem',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataRegistroCVM',
            headerName: 'DataRegistroCVM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataConstituicao',
            headerName: 'DataConstituicao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'CodigoCVM',
            headerName: 'CodigoCVM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SetorCVM',
            headerName: 'SetorCVM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoMercado',
            headerName: 'TipoMercado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ControleAcionario',
            headerName: 'ControleAcionario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoEndereco',
            headerName: 'TipoEndereco',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Logradouro',
            headerName: 'Logradouro',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Complemento',
            headerName: 'Complemento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Municipio',
            headerName: 'Municipio',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'UF',
            headerName: 'UF',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Pais',
            headerName: 'Pais',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CEP',
            headerName: 'CEP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDITelefone',
            headerName: 'DDITelefone',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDDTelefone',
            headerName: 'DDDTelefone',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Telefone',
            headerName: 'Telefone',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDIFax',
            headerName: 'DDIFax',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDDFax',
            headerName: 'DDDFax',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Fax',
            headerName: 'Fax',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CargoResponsavel',
            headerName: 'CargoResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Responsavel',
            headerName: 'Responsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataResponsavel',
            headerName: 'DataResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'LogradouroResponsavel',
            headerName: 'LogradouroResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ComplementoResponsavel',
            headerName: 'ComplementoResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'BairroResponsavel',
            headerName: 'BairroResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'MunicipioResponsavel',
            headerName: 'MunicipioResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'UFResponsavel',
            headerName: 'UFResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PaisResponsavel',
            headerName: 'PaisResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CEPResponsavel',
            headerName: 'CEPResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDDTelefoneResponsavel',
            headerName: 'DDDTelefoneResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TelefoneResponsavel',
            headerName: 'TelefoneResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DDDFaxResponsavel',
            headerName: 'DDDFaxResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FaxResponsavel',
            headerName: 'FaxResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'EmailResponsavel',
            headerName: 'EmailResponsavel',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CNPJAuditor',
            headerName: 'CNPJAuditor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Auditor',
            headerName: 'Auditor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                // customFilter={props.customFilter !== undefined ? props.customFilter : true}
                disableToolbar={props.disableToolbar ? props.disableToolbar : false} />
        </div>
    )
}

ParameterDatalakeCVMCadastroEmpresasTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
    // #customFilter: PropTypes.bool,
    filters: PropTypes.array,
    disableToolbar: PropTypes.bool,
}

export default ParameterDatalakeCVMCadastroEmpresasTable
import {
    DATALAKE_ALIAS_NOMES_DEVEDORES_PATH,
    DATALAKE_B3_ATIVOS_REG_PATH,
    DATALAKE_B3_NEG_ANALITICO_PATH,
    DATALAKE_B3_NEG_CONSOLIDADO_PATH,
    DATALAKE_CONTRAPARTE_PATH,
    DATALAKE_CVM_FATOS_RELEVANTES_PATH,
    DATALAKE_FINCS_EVENTOS_PATH,
    DATALAKE_FINCS_JUROS_PATH,
    DATALAKE_FINCS_MARKET_DATA_PATH,
    DATALAKE_INDICE_INFLACAO_PATH,
    DATALAKE_LIQUIDEZ_PATH,
    DATALAKE_PRECOS_PROCESSADOS_PATH,
    DATALAKE_PRECOS_SPREADS_INDICATIVOS_PATH,
    DATALAKE_PRECOS_SREADS_RELATIVOS_PATH,
    DATALAKE_PRECOS_TUNEL_PATH,
    DATALAKE_PREVIA_INFLACAO_PATH,
    DATALAKE_RATING_DEVEDOR_PATH,
    DATALAKE_RATING_EMISSAO_PATH,
    DATALAKE_PRECOS_ANDADOS_PATH,
    DATALAKE_SPREADS_NEGOCIADOS,
    DATALAKE_TAXAS_JUROS,
    DATALAKE_TAXAS_TR_PATH,
    DATALAKE_RISK_FREE,
    DATALAKE_SPREADS_EMISSAO,
    DATALAKE_SPREADS_IF_NEG,
    DATALAKE_CURVAS_SPREADS_INDICATIVOS,
    DATALAKE_CALENDARIO_INFLACAO,
    DATALAKE_EMISSAO_CARACTERISTICAS_PATH,
    DATALAKE_EMISSAO_LTV_PATH,
    DATALAKE_SPREADS_INDICATIVOS_BRUTO_PATH,
    DATALAKE_ATUALIZACAO_INDICES_PATH,
    DATALAKE_SPREADS_DEVEDOR_PATH,
    DATALAKE_SPREADS_LIQUIDEZ_PATH,
    DATALAKE_SPREADS_SEC_PULVERIZADO_PATH,
    DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH,
    DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH,
    DATALAKE_PRECOS_EVENTOS_FINANCEIROS_PATH,
    DATALAKE_PRECOS_INADIMPLENTES_LGD_PATH,
    DATALAKE_BACEN_SEGMENTACAO_PATH,
    DATALAKE_BACEN_CADASTRO_CONGLOMERADO_PATH,
    DATALAKE_BACEN_CADASTRO_EMPRESA_PATH,
    DATALAKE_CVM_CADASTRO_EMPRESAS_PATH,
    DATALAKE_CVM_CADASTRO_EMISSOR_PATH,
    DATALAKE_CVM_CADASTRO_SITUACAO_PATH,
    RATER_MM_COTACOES_PATH,
    RATER_MM_COTACOES_ACOES_B3_AJUSTADO_PATH,
    RATER_MM_PD_PATH,
    RATER_MM_RESULTADO_EMPRESA_PATH,
    RATER_MM_VOLATILIDADE_TICKER_PATH,
    RATER_MM_TICKER_CNPJ_PATH,
    RATER_USER_CADASTRO_EMPRESA_PATH,
    RATER_USER_SETORIAL_PATH,
    RATER_MM_RESULTADO_RATING_PATH,
    DATALAKE_SETORIAL_PERSPECTIVA_PATH,
    DATALAKE_PERIODICIDADE_BALANCO_PATH,
    DATALAKE_BROADCAST_COTACOES_ACOES_B3_PATH,
    DATALAKE_B3_TICKER_CNPJ_PATH
} from "src/utils/api-routes"


export const parameterDatalakeTableList = [
    { value: "DL_AliasNomesDevedores", description: "DL_AliasNomesDevedores" },
    { value: "DL_AtualizacaoIndices", description: "DL_AtualizacaoIndices" },
    { value: "DL_B3AtivosReg", description: "DL_B3AtivosReg" },
    { value: "DL_B3NegAnalitico", description: "DL_B3NegAnalitico" },
    { value: "DL_B3NegConsolidado", description: "DL_B3NegConsolidado" },
    { value: "DL_B3TickerCNPJ", description: "DL_B3TickerCNPJ" },
    { value: "DL_BACENSegmentacao", description: "DL_BACENSegmentacao" },
    { value: "DL_BACENCadastroConglomerado", description: "DL_BACENCadastroConglomerado" },
    { value: "DL_BACENCadastroEmpresa", description: "DL_BACENCadastroEmpresa" },
    { value: "DL_BroadcastCotacoesAcoesB3", description: "DL_BroadcastCotacoesAcoesB3" },
    { value: "DL_CalendarioInflacao", description: "DL_CalendarioInflacao" },
    { value: "DL_Contraparte", description: "DL_Contraparte" },
    { value: "DL_CurvasSpreadsIndicativos", description: "DL_CurvasSpreadsIndicativos" },
    { value: "DL_CVMCadastroEmissor", description: "DL_CVMCadastroEmissor" },
    { value: "DL_CVMCadastroEmpresas", description: "DL_CVMCadastroEmpresas" },
    { value: "DL_CVMCadastroSituacao", description: "DL_CVMCadastroSituacao" },
    { value: "DL_CVMFatosRelevantes", description: "DL_CVMFatosRelevantes" },
    { value: "DL_EmissaoCarateristicas", description: "DL_EmissaoCarateristicas" },
    { value: "DL_EmissaoLTV", description: "DL_EmissaoLTV" },
    { value: "DL_EventosCreditoDevedor", description: "DL_EventosCreditoDevedor" },
    { value: "DL_EventosCreditoEmissao", description: "DL_EventosCreditoEmissao" },
    { value: "DL_IndiceInflacao", description: "DL_IndiceInflacao" },
    { value: "DL_Liquidez", description: "DL_Liquidez" },
    { value: "DL_PeriodicidadeBalanco", description: "DL_PeriodicidadeBalanco" },
    { value: "DL_PrecosAndados", description: "DL_PrecosAndados" },
    { value: "DL_PrecosEventosFinanceiros", description: "DL_PrecosEventosFinanceiros" },
    { value: "DL_PrecosInadimplentesLGD", description: "DL_PrecosInadimplentesLGD" },
    { value: "DL_PrecosProcessados", description: "DL_PrecosProcessados" },      
    { value: "DL_PrecosSpreadsIndicativos", description: "DL_PrecosSpreadsIndicativos" },
    { value: "DL_PrecosSpreadsRelativos", description: "DL_PrecosSpreadsRelativos" },
    { value: "DL_PrecosTunel", description: "DL_PrecosTunel" },
    { value: "DL_PreviaInflacao", description: "DL_PreviaInflacao" },
    { value: "DL_RatingDevedor", description: "DL_RatingDevedor" },
    { value: "DL_RatingEmissao", description: "DL_RatingEmissao" },   
    { value: "DL_RiskFree", description: "DL_RiskFree" },
    { value: "DL_SetorialPerspectiva", description: "DL_SetorialPerspectiva" },  
    { value: "DL_SpreadsEmissao", description: "DL_SpreadsEmissao" },  
    { value: "DL_SpreadsIFNeg", description: "DL_SpreadsIFNeg" },
    { value: "DL_SpreadsDevedor", description: "DL_SpreadsDevedor" },
    { value: "DL_SpreadsIndicativosBruto", description: "DL_SpreadsIndicativosBruto" },
    { value: "DL_SpreadsLiquidez", description: "DL_SpreadsLiquidez" },
    { value: "DL_SpreadsNegociados", description: "DL_SpreadsNegociados" },
    { value: "DL_SpreadsSecPulverizado", description: "DL_SpreadsSecPulverizado" },
    { value: "DL_TaxasJuros", description: "DL_TaxasJuros" },
    { value: "DL_TaxasTR", description: "DL_TaxasTR" },
    { value: "DL_FINCS_Eventos", description: "FINCS_Eventos" },
    { value: "DL_FINCS_Juros", description: "FINCS_Juros" },
    { value: "DL_FINCS_MarketData", description: "FINCS_MarketData" },
    { value: "RT_UserCadastroEmpresas", description: "RT_UserCadastroEmpresas" },
    { value: "RT_UserSetorial", description: "RT_Usersetorial" },
    { value: "RT_MMCotacoes", description: "RT_MMCotacoes" },
    { value: "RT_MMCotacoesAcoesB3Ajustado", description: "RT_MMCotacoesAcoesB3Ajustado" },
    { value: "RT_MMPD", description: "RT_MMPD" },
    { value: "RT_MMResultadoEmpresa", description: "RT_MMResultadoEmpresa" },
    { value: "RT_MMResultadoRating", description: "RT_MMResultadoRating" },
    { value: "RT_MMTickerCNPJ", description: "RT_MMTickerCNPJ" },
    { value: "RT_MMVolatilidadeTicker", description: "RT_MMVolatilidadeTicker" },

]

export const parameterDatalakeEndpoint = [
    { value: "DL_Contraparte", endpoint: DATALAKE_CONTRAPARTE_PATH },
    { value: "DL_RatingEmissao", endpoint: DATALAKE_RATING_EMISSAO_PATH },
    { value: "DL_RatingDevedor", endpoint: DATALAKE_RATING_DEVEDOR_PATH },
    { value: "DL_AliasNomesDevedores", endpoint: DATALAKE_ALIAS_NOMES_DEVEDORES_PATH },
    { value: "DL_PrecosProcessados", endpoint: DATALAKE_PRECOS_PROCESSADOS_PATH },
    { value: "DL_PrecosSpreadsIndicativos", endpoint: DATALAKE_PRECOS_SPREADS_INDICATIVOS_PATH },
    { value: "DL_PrecosTunel", endpoint: DATALAKE_PRECOS_TUNEL_PATH },
    { value: "DL_PreviaInflacao", endpoint: DATALAKE_PREVIA_INFLACAO_PATH },
    { value: "DL_PrecosSpreadsRelativos", endpoint: DATALAKE_PRECOS_SREADS_RELATIVOS_PATH },
    { value: "DL_CVMFatosRelevantes", endpoint: DATALAKE_CVM_FATOS_RELEVANTES_PATH },
    { value: "DL_IndiceInflacao", endpoint: DATALAKE_INDICE_INFLACAO_PATH },
    { value: "DL_Liquidez", endpoint: DATALAKE_LIQUIDEZ_PATH },
    { value: "DL_B3AtivosReg", endpoint: DATALAKE_B3_ATIVOS_REG_PATH },
    { value: "DL_B3NegAnalitico", endpoint: DATALAKE_B3_NEG_ANALITICO_PATH },
    { value: "DL_B3NegConsolidado", endpoint: DATALAKE_B3_NEG_CONSOLIDADO_PATH },
    { value: "DL_FINCS_MarketData", endpoint: DATALAKE_FINCS_MARKET_DATA_PATH },
    { value: "DL_FINCS_Juros", endpoint: DATALAKE_FINCS_JUROS_PATH },
    { value: "DL_FINCS_Eventos", endpoint: DATALAKE_FINCS_EVENTOS_PATH },
    { value: "DL_SpreadsNegociados", endpoint: DATALAKE_SPREADS_NEGOCIADOS },
    { value: "DL_TaxasJuros", endpoint: DATALAKE_TAXAS_JUROS },
    { value: "DL_TaxasTR", endpoint: DATALAKE_TAXAS_TR_PATH },
    { value: "DL_RiskFree", endpoint: DATALAKE_RISK_FREE },
    { value: "DL_SpreadsEmissao", endpoint: DATALAKE_SPREADS_EMISSAO },
    { value: "DL_SpreadsIFNeg", endpoint: DATALAKE_SPREADS_IF_NEG },
    { value: "DL_CurvasSpreadsIndicativos", endpoint: DATALAKE_CURVAS_SPREADS_INDICATIVOS },
    { value: "DL_CalendarioInflacao", endpoint: DATALAKE_CALENDARIO_INFLACAO },
    { value: "DL_PrecosAndados", endpoint: DATALAKE_PRECOS_ANDADOS_PATH },
    { value: "DL_EmissaoCarateristicas", endpoint: DATALAKE_EMISSAO_CARACTERISTICAS_PATH },
    { value: "DL_EmissaoLTV", endpoint: DATALAKE_EMISSAO_LTV_PATH },
    { value: "DL_SpreadsIndicativosBruto", endpoint: DATALAKE_SPREADS_INDICATIVOS_BRUTO_PATH },
    { value: "DL_AtualizacaoIndices", endpoint: DATALAKE_ATUALIZACAO_INDICES_PATH },
    { value: "DL_SpreadsDevedor", endpoint: DATALAKE_SPREADS_DEVEDOR_PATH },
    { value: "DL_SpreadsLiquidez", endpoint: DATALAKE_SPREADS_LIQUIDEZ_PATH },
    { value: "DL_SpreadsSecPulverizado", endpoint: DATALAKE_SPREADS_SEC_PULVERIZADO_PATH },
    { value: "DL_EventosCreditoDevedor", endpoint: DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH },
    { value: "DL_EventosCreditoEmissao", endpoint: DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH },
    { value: "DL_PrecosEventosFinanceiros", endpoint: DATALAKE_PRECOS_EVENTOS_FINANCEIROS_PATH },
    { value: "DL_PrecosInadimplentesLGD", endpoint: DATALAKE_PRECOS_INADIMPLENTES_LGD_PATH },
    { value: "DL_BACENSegmentacao", endpoint: DATALAKE_BACEN_SEGMENTACAO_PATH }, 
    { value: "DL_BACENCadastroConglomerado", endpoint: DATALAKE_BACEN_CADASTRO_CONGLOMERADO_PATH }, 
    { value: "DL_BACENCadastroEmpresa", endpoint: DATALAKE_BACEN_CADASTRO_EMPRESA_PATH },    
    { value: "DL_CVMCadastroEmpresas", endpoint: DATALAKE_CVM_CADASTRO_EMPRESAS_PATH },
    { value: "DL_CVMCadastroEmissor", endpoint: DATALAKE_CVM_CADASTRO_EMISSOR_PATH },
    { value: "DL_CVMCadastroSituacao", endpoint: DATALAKE_CVM_CADASTRO_SITUACAO_PATH },
    { value: "RT_MMCotacoes", endpoint: RATER_MM_COTACOES_PATH },
    { value: "RT_MMCotacoesAcoesB3Ajustado", endpoint: RATER_MM_COTACOES_ACOES_B3_AJUSTADO_PATH },
    { value: "RT_MMPD", endpoint: RATER_MM_PD_PATH },
    { value: "RT_MMResultadoEmpresa", endpoint: RATER_MM_RESULTADO_EMPRESA_PATH },
    { value: "RT_MMResultadoRating", endpoint: RATER_MM_RESULTADO_RATING_PATH },
    { value: "RT_MMTickerCNPJ", endpoint: RATER_MM_TICKER_CNPJ_PATH },
    { value: "RT_MMVolatilidadeTicker", endpoint: RATER_MM_VOLATILIDADE_TICKER_PATH },
    { value: "RT_UserCadastroEmpresas", endpoint: RATER_USER_CADASTRO_EMPRESA_PATH },
    { value: "RT_UserSetorial", endpoint: RATER_USER_SETORIAL_PATH },
    { value: "DL_SetorialPerspectiva", endpoint: DATALAKE_SETORIAL_PERSPECTIVA_PATH },
    { value: "DL_PeriodicidadeBalanco", endpoint: DATALAKE_PERIODICIDADE_BALANCO_PATH },
    { value: "DL_B3TickerCNPJ", endpoint: DATALAKE_B3_TICKER_CNPJ_PATH },
    { value: "DL_BroadcastCotacoesAcoesB3", endpoint: DATALAKE_BROADCAST_COTACOES_ACOES_B3_PATH },

]
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DATALAKE_BACEN_CADASTRO_CONGLOMERADO_PATH } from 'src/utils/api-routes'
import ParameterDatalakeBACENCadastroConglomeradoTable from 'src/pages/parameters/parameters-datalake/tables/parameter-datalake-bacen-cadastro-conglomerado-table'

const TableCadastroBacen = ({ loading, empresa }) => {
    const [filters, setFilters] = useState([])
    const PAGE_DEFAULT_SIZE = 25
    const PAGE_DEFAULT_INIT = 0
    const [pagination] = useState({ init: PAGE_DEFAULT_INIT, end: PAGE_DEFAULT_SIZE })
    const [tableKey, setTableKey] = useState(Math.random())
    

    useEffect(() => {
        loadFilter()
    }, [empresa])



    const loadFilter = () => {
        let newFilters = []

        if (empresa && empresa !== undefined) {
            newFilters.push({ field: 'CPFCNPJ', value: empresa })
        }

        setFilters(newFilters)
        setTableKey(Math.random())
    }


    return <ParameterDatalakeBACENCadastroConglomeradoTable
        key={tableKey}
        customFilter={false}
        filters={filters}
        endpoint={DATALAKE_BACEN_CADASTRO_CONGLOMERADO_PATH}
        loading={loading}
        pagination={pagination}
        />
}

TableCadastroBacen.propTypes = {
    loading: PropTypes.func,
    empresa: PropTypes.object
}

export default TableCadastroBacen